import { Box, Button, InputBase, ListItemText, Typography } from '@mui/material'
import ModalSelect from '../ModalSelect'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useQuery } from '@tanstack/react-query'
import { LocalAddLowInventoryAlertRequest } from 'src/types/local/localEventAnalyticsTypes'
import { ApiDeleteLowInventoryAlertRequest } from 'src/types/api/apiEventAnalyticsTypes'
import { GET_SECTIONS_QUERY_KEY } from 'src/common/constants/queryKeys'
import { EventAPI } from 'src/services/eventAnalyticsAPI'
import { StyledCheckBox, StyledMenuItem } from '../../generalComponents/TableCoponents'
import { useUserLimits } from 'src/hooks/useLimitsContext'
import { isEqual } from 'lodash'

export default function FilterInvModal({
    setFilterModal = () => {},
    handleDelete = () => {},
    setInvModal,
    deleteLow = false,
    selectedDrop = [],
    eventId,
    setPopupText,
    selectedEvent,
    handleRefetch,
}: {
    eventId: string
    deleteLow?: boolean
    selectedItem?: any
    selectedDrop?: any[]
    selectedEvent?: any
    setFilterModal?: React.Dispatch<SetStateAction<boolean>>
    setInvModal: React.Dispatch<SetStateAction<boolean>>
    handleDelete?: (params: ApiDeleteLowInventoryAlertRequest) => void
    onAddInvAlert?: (params: LocalAddLowInventoryAlertRequest) => void
    setPopupText?: (value: string) => void
    handleRefetch?: () => void
}) {
    const [initiatedEventId, setInitiatedEventId] = useState<string>('')
    const [initiatedAlertId, setInitiatedAlertId] = useState<string>('')
    const [localSections, setLocalSections] = useState<any>([])
    const [count, setCount] = useState<number>(1)

    const { wasteTmLowInventory } = useUserLimits()

    const {
        data: dataGetSectionOptions,
        isLoading,
        isRefetching,
        isError,
    } = useQuery({
        queryKey: [GET_SECTIONS_QUERY_KEY, eventId],
        queryFn: () => EventAPI.getSections(eventId),
        enabled: !!eventId,
    })

    const serverOptions = useMemo(
        () => (dataGetSectionOptions?.data?.data ? [...dataGetSectionOptions?.data?.data] : []),
        [dataGetSectionOptions]
    )

    useEffect(() => {
        if (!serverOptions || !dataGetSectionOptions || isLoading || isRefetching || isError) {
            setInitiatedEventId('')
            setInitiatedAlertId('')
            return
        }

        if (
            selectedDrop[0]
                ? initiatedAlertId === selectedDrop[0]?._id &&
                  localSections.length !== selectedDrop[0]?.sections_to_track?.length
                : initiatedEventId === eventId &&
                  (localSections.length === 0 || localSections.length !== serverOptions.length)
        ) {
            return
        }

        if (selectedDrop[0]?.sections_to_track?.length > 0) {
            setLocalSections(selectedDrop[0]?.sections_to_track)
            setCount(selectedDrop[0]?.ticket_count || 1)
        } else {
            setLocalSections(serverOptions)
        }

        // Update the event and alert IDs to track changes
        setInitiatedEventId(eventId)
        setInitiatedAlertId(selectedDrop[0]?._id)
    }, [
        serverOptions,
        selectedDrop,
        eventId,
        initiatedAlertId,
        initiatedEventId,
        localSections,
        dataGetSectionOptions,
        isLoading,
        isRefetching,
        isError,
    ])

    const mutateAddLowInventoryItem = async ({ eventId, sections_to_track, ticket_count }) => {
        await EventAPI.addLowInventoryAlert({
            eventId,
            sections_to_track,
            ticket_count,
        })
        if (handleRefetch) {
            handleRefetch()
        }
    }

    const mutateUpdateLowInventoryItem = async ({ sections_to_track, ticket_count }) => {
        await EventAPI.updateLowInventoryAlert({
            alertId: selectedDrop[0]?._id,
            sections_to_track,
            ticket_count,
        })
        if (handleRefetch) {
            handleRefetch()
        }
        setCount(1)
        setLocalSections([])
        setInitiatedAlertId('')
    }

    const handleChangeArea = (event: React.ChangeEvent<{ value: unknown }>) => {
        let selectedOptions = event.target.value as string[]
        if (selectedOptions.includes('Entire event')) {
            setLocalSections((prev) => {
                const result =
                    prev.length === serverOptions.length
                        ? selectedOptions.length > 1
                            ? prev.filter((prev) => !selectedOptions.filter((v) => v !== 'Entire event').includes(prev))
                            : []
                        : serverOptions
                return result
            })
            return
        }

        setLocalSections(selectedOptions)
    }

    const handleCancel = () => {
        setCount(1)
        setLocalSections([])
        setFilterModal(true)
        setInvModal(false)
    }
    const handleDeleteLow = () => {
        setCount(1)
        setLocalSections([])
        handleDelete({ alertId: selectedDrop[0]?._id })
        setInvModal(false)
    }

    const handleSaveAlert = async () => {
        if (selectedDrop && selectedDrop[0]) {
            await mutateUpdateLowInventoryItem({
                sections_to_track: localSections,
                ticket_count: count,
            })
        } else {
            await mutateAddLowInventoryItem({
                eventId,
                sections_to_track: localSections,
                ticket_count: count,
            })
        }

        wasteTmLowInventory()
        setPopupText('Added Low Inventory Alert')
        setInvModal(true)
        setCount(1)
        setLocalSections([])
        setInitiatedEventId('')
        setInitiatedAlertId('')
    }

    const handleChangeCount = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCount(Math.max(Number(event.target.value), 0))
    }
    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',
                flexDirection: 'column',
                width: '550px',
                gap: '20px',
                zIndex: '1500',
                display: 'flex',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography sx={{ fontSize: '24px', fontWeight: '600', color: '#001A63FF' }}>
                    Low Inventory Alert
                </Typography>
                {selectedEvent && (
                    <Typography sx={{ fontSize: '14px', color: '#6072a6', marginTop: '16px' }}>
                        {selectedEvent?.name}
                    </Typography>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <ModalSelect
                    handleChange={handleChangeArea}
                    multiple={true}
                    value={isLoading || isRefetching || isError ? ['Loading...'] : localSections}
                    label="Area"
                    isAllSelected={localSections.length === serverOptions.length && serverOptions.length > 0}
                    allSelectedLabel="Entire event"
                >
                    {isLoading || isRefetching || isError ? (
                        <StyledMenuItem key={'Loading...'} value={'Loading...'}>
                            <ListItemText primary={'Loading...'} />
                        </StyledMenuItem>
                    ) : (
                        <StyledMenuItem key={'Entire event'} value={'Entire event'}>
                            <StyledCheckBox
                                sx={{ color: '#000000' }}
                                checked={localSections.length === serverOptions.length && serverOptions.length > 0}
                                checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                            />
                            <ListItemText primary={'Entire event'} />
                        </StyledMenuItem>
                    )}
                    {serverOptions?.map((ticket) => {
                        return (
                            <StyledMenuItem key={ticket} value={ticket}>
                                <StyledCheckBox
                                    sx={{ color: 'background.black' }}
                                    checked={localSections?.indexOf(ticket) > -1}
                                    checkedIcon={<CheckBoxIcon sx={{ color: 'background.black' }} />}
                                />
                                <ListItemText primary={ticket} />
                            </StyledMenuItem>
                        )
                    })}
                </ModalSelect>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Notification count :</Typography>
                    <InputBase
                        sx={{
                            bgcolor: 'white',
                            border: 1,
                            borderColor: 'grey.400',
                            width: '100%',
                            borderRadius: '6px',
                            height: '45px',
                            px: 2,
                        }}
                        type="number"
                        value={String(count)}
                        onChange={handleChangeCount}
                    ></InputBase>
                </Box>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    width: '100%',
                    gap: '12px',
                }}
            >
                <Button
                    onClick={handleSaveAlert}
                    sx={{
                        bgcolor: '#001A63FF',
                        color: 'text.default',
                        '&:hover': { bgcolor: '#001A63FF' },
                        px: '16px',
                        py: '8px',
                    }}
                >
                    SAVE SETTINGS
                </Button>
                {deleteLow && selectedDrop && selectedDrop[0]?._id && (
                    <Button
                        onClick={handleDeleteLow}
                        sx={{
                            bgcolor: '#E72929',
                            color: 'text.default',
                            '&:hover': { bgcolor: '#E72929' },
                            px: '16px',
                            py: '8px',
                        }}
                    >
                        DELETE
                    </Button>
                )}
                <Button
                    onClick={handleCancel}
                    sx={{
                        bgcolor: 'background.primary',
                        color: 'text.primary',
                        '&:hover': { bgcolor: 'background.primary' },
                        px: '16px',
                        py: '8px',
                    }}
                >
                    CLOSE
                </Button>
            </Box>
        </Box>
    )
}
