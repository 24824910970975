import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M1.66602 8.33333C1.66602 8.33333 1.76712 7.62563 4.69605 4.6967C7.62498 1.76777 12.3737 1.76777 15.3026 4.6967C16.3404 5.73443 17.0104 7.0006 17.3128 8.33333M1.66602 8.33333V3.33333M1.66602 8.33333H6.66602M18.3327 11.6667C18.3327 11.6667 18.2316 12.3744 15.3026 15.3033C12.3737 18.2322 7.62498 18.2322 4.69605 15.3033C3.65832 14.2656 2.98826 12.9994 2.68587 11.6667M18.3327 11.6667V16.6667M18.3327 11.6667H13.3327"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>,
    'RestockIcon'
  );
