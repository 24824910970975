import { Box, Link } from '@mui/material'
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import { StyledTableIconButton } from 'src/components/general/table/StyledTableIconButton'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

export function BuyingAcionBox({
    handleDelete,
    handleChange,
    handleSave,
    handleOpenEventAnalytics,
    eventId,
}: {
    handleDelete: () => void
    handleChange: () => void
    handleSave: () => void
    handleOpenEventAnalytics: () => void
    eventId
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'wrap',
                gap: '5px',
                width: '61px',
            }}
        >
            <StyledTableIconButton onClick={handleDelete} size="small" sx={{ borderRadius: '6px', bgcolor: 'white' }}>
                <DeleteSharpIcon sx={{ fontSize: '16px', color: '#E4933D' }} />
            </StyledTableIconButton>
            <StyledTableIconButton onClick={handleChange} size="small" sx={{ borderRadius: '6px', bgcolor: 'white' }}>
                <ModeEditOutlineOutlinedIcon sx={{ fontSize: '16px', color: '#E4933D' }} />
            </StyledTableIconButton>
            <StyledTableIconButton
                onClick={handleOpenEventAnalytics}
                size="small"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Link
                    target="_blank"
                    href={`/dashboard/primary-analytics/${eventId}`}
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                    <OpenInNewIcon sx={{ color: '#E4933D', width: '19px' }} />
                </Link>
            </StyledTableIconButton>
            <StyledTableIconButton onClick={handleSave} size="small">
                <AddBoxOutlinedIcon sx={{ color: '#E4933D', width: '19px' }} />
            </StyledTableIconButton>
        </Box>
    )
}
