export const headers = [
  "Event",
  "Ranking",
  "Date",
  "Venue",
  "Location",
  "Capacity",
  "Presale/Public Sale",
  "Ticket Limit & Price Range",
  "My Notes",
  "ACTION",
];
export let layoutGrid = "220px 40px 60px 0.9fr 60px 0.8fr 0.7fr 150px 60px 90px 1.1fr 1.1fr";
