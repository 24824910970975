import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Backdrop, Box, IconButton, ListItemText, Paper, Slide, Snackbar, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import { DirectLinks } from 'src/components/dashboard/eventAnalytics/directLinks'
import { AvatarModal } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModals/AvatarModal'
import { PresaleInformationModal } from 'src/components/dashboard/overview/PresaleInformationModal'
import axiosClient from 'src/utils/axios'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { GET_BUYING_LIST } from 'src/common/constants/queryKeys'
import { BuyingListTable } from 'src/components/dashboard/buyingList'
import { NoteModal } from 'src/components/dashboard/eventAnalytics/NoteModal'
import { SavingModal } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModals/SavingModal'
import PaginationBottomComponent from 'src/components/dashboard/generalComponents/StyledPagination'
import { CustomSwitch } from 'src/components/general/CustomSwitch'
import DebouncedInput from 'src/components/general/DebouncedInput'
import { useBuyingList } from 'src/hooks/useBuyingList'
import SearchIcon from 'src/icons/SearchIcon'
import { BuyingListAPI } from 'src/services/buyingListAPI'
import { FrontEndError } from 'src/utils/error'
import { UpcomingSalesFilters } from 'src/components/dashboard/overview/AdvanceFilters'
import { FirstSearchInfoModal } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModals/FirstSearchInfoModal'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import {
    categories as defaultCategories,
    countries,
    platformSelectArray,
    defaultSliderValue,
} from 'src/common/constants/overview'
import useAuth from 'src/hooks/useAuth'
import { StyledCheckBox } from 'src/components/dashboard/generalComponents/TableCoponents'

const BuyingList: FC = () => {
    const {
        page,
        perPage,
        allEntries,
        valueInput,
        selectedItem,
        sliderValue,
        selectedMeta,
        filterShow,
        filtringState,
        popupText,
        searchParams,
        setFilterShow,
        setSelectedMeta,
        setPopupText,
        setSelectedItem,
        setSearchParams,
        setFilteringState,
        handleChangePage,
        handleChangePerPage,
        setAllEntries,
        handleChangeInput,
        isDisabledFilters,
        timezone,
        setTimezone,
        handleChangeSlider,
        setSliderValue,
        isIncludesTeams,
        setIsIncludesTeams,
    } = useBuyingList()

    const {
        user: { plans },
    } = useAuth()

    const [ticker, setTicker] = useState()

    const setFilterState = () => {
        setFilterShow((filterShow) => !filterShow)
    }

    const handleSubmitNoteUpdate = ({ eventId, note }) => {
        handleEdit(eventId, note)
        setPopupText('Event notes updated')
    }

    const [categories, setCategories] = useState([])

    useEffect(() => {
        setCategories(filtringState.categories.map((str) => (str.includes('&') ? str.replace('&', '%26') : str)))
    }, [filtringState])

    const requestInfo = useMemo(() => {
        return {
            categories: categories === defaultCategories ? [] : categories,
            valueInput,
            filtringState: {
                ...filtringState,
                countries: filtringState.countries === countries ? [] : filtringState.countries,
                platformSelect:
                    filtringState.platformSelect.join(',') === platformSelectArray.map((elem) => elem.name).join(',')
                        ? []
                        : filtringState.platformSelect,
                eventSelect: filtringState.eventSelect.length ? filtringState.eventSelect : ['all'],
            },
            searchParams,
            isDisabledFilters,
            ...(sliderValue.spotifyRankValue[0] !== defaultSliderValue.spotifyRankValue[0] && {
                spotifyMin: sliderValue.spotifyRankValue[0],
            }),
            ...(sliderValue.spotifyRankValue[1] !== defaultSliderValue.spotifyRankValue[1] && {
                spotifyMax: sliderValue.spotifyRankValue[1],
            }),
            ...(sliderValue.capacityValue[0] !== defaultSliderValue.capacityValue[0] && {
                capacityMin: sliderValue.capacityValue[0],
            }),
            ...(sliderValue.capacityValue[1] !== defaultSliderValue.capacityValue[1] && {
                capacityMax: sliderValue.capacityValue[1],
            }),
            priceMin: sliderValue.priceValue[0],
            priceMax: sliderValue.priceValue[1],
            filterShow,
            isIncludesTeams,
        }
    }, [ticker])

    const { data: dataPresales } = useQuery({
        queryKey: [
            GET_BUYING_LIST,
            perPage,
            page,
            valueInput,
            isDisabledFilters,
            requestInfo,
            searchParams.toString(),
            isIncludesTeams,
        ],
        queryFn: () =>
            BuyingListAPI.getBuyingList({
                perPage,
                page,
                valueInput,
                searchParams,
                isDisabledFilters,
                includeTeam: isIncludesTeams,
                ...requestInfo,
            }),
    })

    useEffect(() => {
        if (dataPresales) {
            // Set Total Events
            setAllEntries(dataPresales.data.total_results)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPresales])

    useEffect(() => {
        handleChangePage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, categories, valueInput, filtringState, isDisabledFilters, sliderValue, searchParams])

    const [selectedNote, setSelectedNote] = useState()
    const [openPresale, setOpenPresale] = useState(false)
    const [openDirectLinks, setOpenDirectLinks] = useState(false)
    const [openSaveModal, setOpenSaveModal] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [edit, setEdit] = useState(false)
    const [noteModal, setNoteModal] = useState(false)
    const [avatarModal, setAvatarModal] = useState(false)
    const [infoModal, setInfoModal] = useState(false)

    const handleClickModalClose = (
        event: React.MouseEvent<HTMLElement>,
        setOpen: React.Dispatch<SetStateAction<boolean>>
    ) => {
        if (event.target === event.currentTarget) {
            setOpen(false)
        }
    }

    const queryClient = useQueryClient()

    const handleDelete = (eventId: string) => {
        axiosClient()
            .delete(`api/user/buying-list/${eventId}`)
            .then(() => {
                setPopupText('Event Deleted')
            })
            .catch((err) => FrontEndError(err))
            .finally(() =>
                queryClient.invalidateQueries({
                    queryKey: [
                        GET_BUYING_LIST,
                        perPage,
                        page,
                        categories,
                        valueInput,
                        filtringState,
                        isDisabledFilters,
                        sliderValue,
                        searchParams.toString(),
                    ],
                })
            )
    }
    const handleEdit = (eventId: string, note: string) => {
        axiosClient()
            .post('/api/user/buying-list', {
                event_id: eventId,
                notes: note,
            })
            .then((resp) => setEdit(true))
            .catch((err) => FrontEndError(err))
            .finally(() =>
                queryClient.invalidateQueries({
                    queryKey: [
                        GET_BUYING_LIST,
                        perPage,
                        page,
                        categories,
                        valueInput,
                        filtringState,
                        isDisabledFilters,
                        sliderValue,
                        searchParams.toString(),
                    ],
                })
            )
    }

    return (
        <>
            <Helmet>
                <title>Buying List | Ticketmetric</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    py: '25px',
                    px: '12px',
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#F7F6F4',
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        py: '57px',
                        px: '51px',
                        height: '100%',
                        boxShadow: 0,
                        borderRadius: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Stack sx={{ mr: '32px' }} alignItems="center">
                            <Typography sx={{ textWrap: 'nowrap', mr: '32px' }} variant="h5">
                                My Buying List
                            </Typography>
                            {plans[0]?.seats > 1 && (
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', mt: '10px' }}>
                                    <StyledCheckBox
                                        sx={{ color: '#000000', p: '0' }}
                                        checked={isIncludesTeams}
                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                        onChange={() => setIsIncludesTeams(!isIncludesTeams)}
                                    />
                                    <ListItemText
                                        primary={'Include Team'}
                                        sx={{
                                            fontWeight: 600,
                                        }}
                                    />
                                </Box>
                            )}
                        </Stack>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: '8px',
                                    padding: '4px 12px',
                                    border: 'solid',
                                    borderWidth: '1px',
                                    borderColor: '#E9D7FE',
                                    mr: '28px',
                                }}
                            >
                                <Typography sx={{ color: '#AE763F' }} noWrap={true}>
                                    {allEntries} events
                                </Typography>
                            </Box>
                            <IconButton
                                sx={{ width: '26px', height: '26px', mr: '4px' }}
                                onClick={() => setInfoModal(true)}
                            >
                                <HelpOutlineOutlinedIcon sx={{ width: '20px', height: '20px', color: '#667085' }} />
                            </IconButton>
                            <Paper
                                component="form"
                                sx={{
                                    border: '1px solid',
                                    borderColor: '#D0D5DD',
                                    borderRadius: '8px',
                                    p: '3px 14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    boxShadow: 0,
                                    mr: '18px',
                                }}
                            >
                                <SearchIcon fontSize="small" sx={{ color: '#667085', fontSize: '20px', mr: '8px' }} />
                                <DebouncedInput
                                    value={valueInput}
                                    onSearch={handleChangeInput}
                                    sx={{ fontSize: '16px', lineHeight: '24px', p: '0', width: '100%' }}
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    debounceTime={300}
                                />
                            </Paper>
                            <Box
                                sx={{
                                    border: '1px solid',
                                    borderColor: '#D0D5DD',
                                    borderRadius: '8px',
                                    p: '9px 7.5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <FilterListIcon fontSize="small" />
                                <Typography
                                    sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '20px' }}
                                    noWrap={true}
                                >
                                    Show Filters
                                </Typography>
                                <CustomSwitch checked={filterShow} onChange={setFilterState} checkedcolor="#204391" />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        {filterShow && (
                            <UpcomingSalesFilters
                                setSwitcher={setTicker}
                                handleChangeSlider={handleChangeSlider}
                                setFilteringState={setFilteringState}
                                setSearchParams={setSearchParams}
                                country={filtringState.countries}
                                platform={filtringState.platformSelect}
                                category={filtringState.categories}
                                valueDate={filtringState.valueDate}
                                eventSelect={filtringState.eventSelect}
                                timeSelect={filtringState.timeSelect}
                                timezone={timezone}
                                setTimezone={setTimezone}
                                sliderValue={sliderValue}
                                setSliderValue={setSliderValue}
                            ></UpcomingSalesFilters>
                        )}
                    </Box>
                </Paper>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        py: '10px',
                        height: '100%',
                    }}
                >
                    {dataPresales ? (
                        <BuyingListTable
                            valueDate={filtringState.valueDate}
                            setOpenSaveModal={setOpenSaveModal}
                            setSelectedNote={setSelectedNote}
                            setOpenAvatar={setAvatarModal}
                            buyingTableData={dataPresales.data}
                            setNoteModal={setNoteModal}
                            setOpenDirectLinks={setOpenDirectLinks}
                            setOpenPresale={setOpenPresale}
                            setSelectedItem={setSelectedItem}
                            setSelectedMeta={setSelectedMeta}
                            handleDelete={handleDelete}
                            timezone={timezone}
                        ></BuyingListTable>
                    ) : null}

                    <Backdrop
                        style={{ zIndex: '6' }}
                        open={openPresale}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setOpenPresale)}
                    >
                        <PresaleInformationModal item={selectedItem} setOpen={setOpenPresale} timezone={timezone} />
                    </Backdrop>
                    <Backdrop
                        style={{ zIndex: '6' }}
                        open={openDirectLinks}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleClickModalClose(event, setOpenDirectLinks)
                        }
                    >
                        <DirectLinks meta={selectedMeta} setOpen={setOpenDirectLinks} />
                    </Backdrop>
                    <Backdrop
                        open={noteModal}
                        style={{ zIndex: '6' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setNoteModal)}
                    >
                        <NoteModal
                            label="Edit Note"
                            buttonLabel="Save"
                            setOpen={setNoteModal}
                            selectedNote={selectedNote}
                            eventId={selectedItem?._id}
                            onSubmit={({ eventId, note }) => handleSubmitNoteUpdate({ eventId, note })}
                        ></NoteModal>
                    </Backdrop>
                    <Backdrop
                        style={{ zIndex: '6' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setAvatarModal)}
                        open={avatarModal}
                    >
                        <AvatarModal
                            artists={selectedItem?.artistsStat}
                            setOpen={setAvatarModal}
                            artistId={selectedItem?._id}
                        />
                    </Backdrop>
                    <Backdrop style={{ zIndex: '6' }} open={openSaveModal} onClick={() => setOpenSaveModal(false)}>
                        <SavingModal
                            setPopupText={setPopupText}
                            event_id={selectedItem?._id}
                            setState={() => setOpenSaveModal(false)}
                        />
                    </Backdrop>
                    <Backdrop
                        style={{ zIndex: '6' }}
                        open={infoModal}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setInfoModal)}
                    >
                        <FirstSearchInfoModal
                            changeModal={() => {
                                setInfoModal((e) => !e)
                            }}
                        />
                    </Backdrop>
                    <PaginationBottomComponent
                        count={Math.ceil(allEntries / perPage)}
                        perPage={perPage}
                        page={page}
                        setPage={handleChangePage}
                        setPerPage={handleChangePerPage}
                    />
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={popupText !== ''}
                autoHideDuration={3000}
                TransitionComponent={Slide}
                onClose={() => {
                    setPopupText('')
                }}
                sx={{ '& .MuiSnackbarContent-root': { minWidth: '100px' } }}
                message={popupText}
            />
        </>
    )
}

export default BuyingList
