import { isDate } from 'date-fns'
import dayjs from 'dayjs'
import { isArray } from 'lodash'
import { Datum } from 'src/types/local/upSalesResp'

export const convertDate = (str: string, inputTimeZone?: string) => {
    if (str === undefined) return '0'
    else {
        const originalDateTime: Date = new Date(str)

        let formatter
        if (inputTimeZone === null) {
            formatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            })
        } else {
            formatter = new Intl.DateTimeFormat('en-US', {
                weekday: 'long',
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
                timeZone: inputTimeZone,
            })
        }

        return `${formatter.format(originalDateTime)}`
    }
}

export function findMaxByStartDateTime(objects: Datum): string | null {
    if (objects?.presales === null || !isArray(objects.presales) ? true : objects?.presales?.length === 0) {
        return objects?.saleStartDate
    }

    let maxObject
    let maxDateTime: Date | null = null

    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const obj of objects?.presales) {
        const currentDateTime: Date = obj?.startDateTime

        if (maxDateTime === null || currentDateTime < maxDateTime) {
            maxDateTime = currentDateTime
            maxObject = obj
        }
    }
    return maxObject?.startDateTime
}

export function findMinStartDateTime(objects: Datum): string | null {
    if (objects?.presales?.length === 0) {
        return null
    }

    let maxObject
    let maxDateTime: Date | null = null

    for (const obj of objects.presales) {
        const currentDateTime: Date = obj?.startDateTime

        if (maxDateTime === null || currentDateTime > maxDateTime) {
            maxDateTime = currentDateTime
            maxObject = obj
        }
    }

    return maxObject?.startDateTime
}

export const FormatDate = (str: string, inputTimeZone?: string | null) => {
    const originalDateTime = new Date(str)
    let formatedDate

    if (inputTimeZone === null) {
        formatedDate = originalDateTime.toLocaleString('en-US')
    } else {
        formatedDate = originalDateTime.toLocaleString('en-US', {
            timeZone: inputTimeZone,
        })
    }

    const NewformatedDate = formatedDate
        .replace('T', ' ')
        .replace('/', '-')
        .replace('/', '-')
        .replace(',', '')
        .replace('Z', '')
        .slice(0, -6)
    return NewformatedDate
}

export const checkingDate = (presale: string, salestart: string, valueDate: Date) => {
    const presaling = new Date(presale)
    const saleStart = new Date(salestart)

    if (!isDate(valueDate)) {
        valueDate = new Date(valueDate)
    }

    return (
        (presaling.getMonth() < saleStart.getMonth() ||
            (presaling.getMonth() === saleStart.getMonth() && presaling.getDate() <= saleStart.getDate())) &&
        (presaling.getMonth() > valueDate.getMonth() ||
            (presaling.getMonth() === valueDate.getMonth() && presaling.getDate() >= valueDate.getDate()))
    )
}

export const formatDate = (date: Date, format: string) => {
    return dayjs(date).format(format)
}

export const applyTimeZone = (str: string, inputTimeZone: string) => {
    if (!str || !inputTimeZone) {
        return new Date(str)
    }
    const originalDateTime = new Date(str)
    return originalDateTime.toLocaleString('en-US', {
        timeZone: inputTimeZone,
    })
}
