import React, { useState } from 'react'
import 'react-calendar/dist/Calendar.css'
import { useSearchParams } from 'react-router-dom'
import { Datum, otherPlatform } from 'src/types/local/upSalesResp'
import useModal from 'src/hooks/useModal'
import { useCustomPagination } from 'src/hooks/usePagination'
import { FilterPresaleInterface } from 'src/types/local/overViewTypes'
import { PresalesAPI } from 'src/services/presalesAPI'
import { SliderValueProps } from 'src/types/local/slidervalueprops'
import { categories, countries, defaultSliderValue, platformSelectArray } from 'src/common/constants/overview'

export const useBuyingList = () => {
    const { isModalOpen, changeModal, handleChangeModal } = useModal()
    const [popupText, setPopupText] = useState('')
    const { page, perPage, totalPages, setAllEntries, handleChangePage, handleChangePerPage, allEntries } =
        useCustomPagination()
    const [selectedId, setSelectedId] = useState()
    const [valueInput, setValueInput] = useState('')
    const [eventSelectDate, setEventSelectDate] = useState<Date>(null)
    const [selectedItem, setSelectedItem] = useState<Datum>()
    const [filterShow, setFilterShow] = useState(false)
    const [selectedMeta, setSelectedMeta] = useState<otherPlatform[]>()
    const [filtringState, setFilteringState] = useState<FilterPresaleInterface>({
        categories: categories,
        countries: countries,
        platformSelect: platformSelectArray.map((e) => e.name),
        eventSelect: ['all_presales', 'all_public_sale'],
        timeSelect: '0:00:00-23:59:59',
        valueDate: new Date(),
    })
    const [searchParams, setSearchParams] = useSearchParams()
    const [isIncludesTeams, setIsIncludesTeams] = useState(false)

    const [timezone, setTimezone] = useState('local')

    const [sliderValue, setSliderValue] = useState<SliderValueProps>(defaultSliderValue)

    const handleChangeInput = (value: string) => {
        setValueInput(value)
    }

    const [isDisabledFilters, setIsDisabledFilters] = useState({
        spotifyRankValue: false,
        capacityValue: false,
        priceValue: true,
        primaryValue: true,
        secondaryValue: true,
        sellouts: true,
    })

    const handleEnterKeyPress = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    const handleInputEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }
    const handleAddToBuying = async (eventId: string, note?: string) => {
        await PresalesAPI.postBuyingList(eventId, note)
        await changeModal('addModal')
    }

    const handleChangeSlider = (event: Event, newValue: number | number[], name: string) => {
        setSliderValue((prevValue) => ({
            ...prevValue,
            [name]: newValue as number[],
        }))
    }

    const handleChangeToggle = (key: string, value: boolean) => {
        setIsDisabledFilters((prevValue) => {
            return {
                ...prevValue,
                [key]: !prevValue[key],
            }
        })
    }
    const handleAddToWatchlist = (eventId: string) => {
        // TODO:
    }

    return {
        page,
        isDisabledFilters,
        sliderValue,
        perPage,
        popupText,
        totalPages,
        allEntries,
        valueInput,
        selectedItem,
        selectedMeta,
        filterShow,
        filtringState,
        eventSelectDate,
        selectedId,
        setEventSelectDate,
        setFilteringState,
        setFilterShow,
        setSelectedId,
        searchParams,
        handleChangeSlider,
        handleChangeToggle,
        setPopupText,
        setIsDisabledFilters,
        setSelectedMeta,
        setSelectedItem,
        setSearchParams,
        isModalOpen,
        changeModal,
        handleChangeModal,
        setSliderValue,
        setAllEntries,
        handleChangePage,
        handleChangePerPage,
        handleAddToBuying,
        handleInputEnter,
        handleEnterKeyPress,
        handleChangeInput,
        handleAddToWatchlist,
        timezone,
        setTimezone,
        isIncludesTeams,
        setIsIncludesTeams,
    }
}
