import createSvgIcon from '@mui/material/utils/createSvgIcon'

const YoutubeIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g id="Youtube" clipPath="url(#clip0_789_13689)">
            <g id="Youtube_2">
                <path
                    id="back"
                    d="M0.144531 8C0.144531 3.58172 3.72625 0 8.14453 0C12.5628 0 16.1445 3.58172 16.1445 8C16.1445 12.4183 12.5628 16 8.14453 16C3.72625 16 0.144531 12.4183 0.144531 8Z"
                    fill="#FF0000"
                />
                <path
                    id="Fill-1"
                    d="M12.2339 6.02415C12.1358 5.64714 11.8466 5.35027 11.4794 5.24952C10.814 5.06641 8.14557 5.06641 8.14557 5.06641C8.14557 5.06641 5.47711 5.06641 4.81166 5.24952C4.44448 5.35027 4.15532 5.64714 4.05719 6.02415C3.87891 6.70742 3.87891 8.13307 3.87891 8.13307C3.87891 8.13307 3.87891 9.55868 4.05719 10.242C4.15532 10.619 4.44448 10.9159 4.81166 11.0167C5.47711 11.1997 8.14557 11.1997 8.14557 11.1997C8.14557 11.1997 10.814 11.1997 11.4794 11.0167C11.8466 10.9159 12.1358 10.619 12.2339 10.242C12.4122 9.55868 12.4122 8.13307 12.4122 8.13307C12.4122 8.13307 12.4122 6.70742 12.2339 6.02415Z"
                    fill="white"
                />
                <path id="Fill-2" d="M7.34375 9.60026V6.93359L9.47708 8.26698L7.34375 9.60026Z" fill="#FF0000" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_789_13689">
                <rect width="16" height="16" fill="white" transform="translate(0.144531)" />
            </clipPath>
        </defs>
    </svg>,
    'Search',
)

export default YoutubeIcon
