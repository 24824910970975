import { Box, Typography } from '@mui/material'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { applyTimeZone } from 'src/utils/dataFormatters'

export const formatCustomDate = (dateTime: string, timezone: string) => {
    const date = new Date(applyTimeZone(dateTime, timezone))
    const timezoneAbbreviation = new Intl.DateTimeFormat('en-US', { timeZone: timezone, timeZoneName: 'short' })
        .formatToParts(date)
        .find((part) => part.type === 'timeZoneName')?.value
    // return format(date, 'EEE, MMM d h:mm a', { locale: enUS }).toUpperCase() + ' ' + (timezoneAbbreviation || '')
    return (
        <Box>
            <Typography sx={{ color: 'black', fontSize: 15, fontWeight: '500' }}>
                {format(date, 'EEE, MMM d', { locale: enUS }).toUpperCase()}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.3rem' }}>
                <Typography sx={{ color: '#595454', fontSize: '13.13px', fontWeight: 'normal' }}>
                    {format(date, 'h:mm a', { locale: enUS }).toUpperCase()}
                </Typography>
                <Typography sx={{ color: '#595454', fontSize: '13.13px', fontWeight: 'normal' }}>
                    {timezoneAbbreviation || ''}
                </Typography>
            </Box>
        </Box>
    )
}
