import { createSvgIcon } from '@mui/material'

const DefaultAvatar = createSvgIcon(
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="#3b5694"></circle>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="30">
            <circle cx="15" cy="15" r="15" fill="#C4C4C4"></circle>
        </mask>
        <g mask="url(#mask0)">
            <path
                d="M25.5567 29.9785L24.8674 26.3281C24.5959 25.1753 23.9274 24.4495 22.7786 24.0439L17.4312 22.6563V21.1406C16.6374 21.5249 15.7601 21.7384 14.8201 21.7384C13.8802 21.7384 12.982 21.5249 12.1673 21.1406V22.6563L6.75726 24.0652C5.62929 24.3854 4.96086 25.282 4.73109 26.3067C4.73109 26.3067 4.39688 27.9932 4 29.9998H25.5567V29.9785Z"
                fill="#748BC2"
            ></path>
            <path
                d="M21.003 12.751V14.5655C21.003 18.0665 18.2248 20.8844 14.82 20.8844C11.4152 20.8844 8.61621 18.0452 8.61621 14.5655V12.751H21.003Z"
                fill="#748BC2"
            ></path>
            <path
                d="M8.61621 14.1598V12.3666C8.61621 8.86559 11.3944 6.02637 14.82 6.02637C18.2457 6.02637 21.003 8.86559 21.003 12.3452V14.1598H8.61621Z"
                fill="#748BC2"
            ></path>
        </g>
    </svg>,
    'avatar'
)

export default DefaultAvatar
