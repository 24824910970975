import dayjs from 'dayjs'
import { BuyingListResponce } from 'src/types/local/buyingListResponce'
import axiosClient from 'src/utils/axios'
import { FrontEndError } from 'src/utils/error'

// interface IUpdatePresaleCode {
//     eventId: string
//     presaleName: string
//     startDateTime: string
//     endDateTime?: string
//     code: string
// }

class OverViewServiceAPI {
    async getBuyingList({
        page,
        perPage,
        valueInput,
        filtringState,
        categories,
        searchParams,
        isDisabledFilters,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        filterShow,
        includeTeam,
    }): Promise<{ data: BuyingListResponce; current_page: number; total_results: number }> {
        try {
            const params = {
                per_page: perPage,
                category: categories.join(','),
                page,
                saleType: filtringState.eventSelect.join(','),
                search: valueInput,
                countryCode: filtringState.countries.join(','),
                ...(filtringState.platformSelect.length && {
                    platform: filtringState.platformSelect.join(','),
                }),
                ...(filtringState.valueDate && {
                    date: dayjs(filtringState.valueDate).format('YYYY-MM-DD'),
                }),
                ...(filtringState.valueDate &&
                    filtringState.timeSelect && {
                        time: filtringState.timeSelect,
                    }),
                spotifyMin,
                spotifyMax,
                capacityMin,
                capacityMax,
                priceMin,
                priceMax,
                include_team: includeTeam,
            }
            if (params.time && params.time.length === 0) delete params.time
            if (!filterShow) {
                delete params.spotifyMin
                delete params.spotifyMax
                delete params.capacityMin
                delete params.capacityMax
                delete params.priceMin
                delete params.priceMax
                delete params.category
                delete params.time
                // delete params.saleType
                delete params.countryCode
            }
            Object.entries(isDisabledFilters).forEach(([key, value]) => {
                if (key === 'spotifyRankValue' && value) {
                    delete params.spotifyMin
                    delete params.spotifyMax
                }
                if (key === 'capacityValue' && value) {
                    delete params.capacityMin
                    delete params.capacityMax
                }
                if (key === 'priceValue' && value) {
                    delete params.priceMin
                    delete params.priceMax
                }
            })
            return await axiosClient().get('/api/user/buying-list', { params })
        } catch (err) {
            FrontEndError(err)
        }
    }
    async getCategories() {
        return await axiosClient().get('/api/events/get-categories')
    }
}

export const BuyingListAPI = new OverViewServiceAPI()
