import { useRoutes } from 'react-router'
import routes from 'src/pages/routes'
//FIXME:
import useScrollReset from '../hooks/useScrollReset'
import useAuth from 'src/hooks/useAuth'
import SplashScreen from '../components/general/SplashScreen'

export const AppRouter = () => {
    const auth = useAuth()
    useScrollReset()

    const router = useRoutes(routes)
    return auth.isInitialized ? router : <SplashScreen />
}
