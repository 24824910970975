import type { FC } from 'react'
import { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'
import '../i18n'
import { gtmConfig } from '../config'
import gtm from '../lib/gtm'

import { MainProvider } from './providers'
import { AppRouter } from '../pages/router'

export const App: FC = () => {
    useEffect(() => {
        gtm.initialize(gtmConfig)
    }, [])

    return (
        <MainProvider>
            <Toaster position="top-center" />
            <AppRouter />
        </MainProvider>
    )
}
