export default function LinkingIcon({ color = '#475467' }) {
    return (
        <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.86173 0.666749H4.5284C2.68745 0.666749 1.19507 2.15913 1.19507 4.00008C1.19507 5.84103 2.68745 7.33342 4.5284 7.33342H5.86173C7.70268 7.33342 9.19507 5.84103 9.19507 4.00008M10.8617 7.33342H11.1951C13.036 7.33342 14.5284 5.84103 14.5284 4.00008C14.5284 2.15913 13.036 0.666748 11.1951 0.666748H9.86173C8.02079 0.666748 6.5284 2.15913 6.5284 4.00008"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}
