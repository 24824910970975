import { useMemo } from 'react'
import { EventType, IPreparedEventType } from 'src/types/local/customTableTypes'
import { otherPlatform } from 'src/types/local/upSalesResp'
import numberWithCommas from 'src/utils/numberWithCommas'

export const useEvents = (data: EventType[]): { eventData: IPreparedEventType[] } => {
    const eventData = useMemo(() => {
        return data.map((event) => {
            const { primaryMetaPlatform, secondaryMetaPlatform, isAXS } = getPlatformsInfo(event.meta.otherPlatforms)

            const isSportCategory = event.category === 'Sports'

            const capacity = getCapacity({
                primaryMetaPlatform,
                secondaryMetaPlatform,
            })

            return {
                ...event,
                primaryMetaPlatform,
                secondaryMetaPlatform,
                isAXS,
                isSportCategory,
                formattedCapacity: capacity,
            }
        })
    }, [data])

    return {
        eventData,
    }
}

const getPlatformsInfo = (
    platforms: otherPlatform[]
): { primaryMetaPlatform: otherPlatform | null; secondaryMetaPlatform: otherPlatform | null; isAXS: boolean } => {
    let primaryMetaPlatform = null
    let secondaryMetaPlatform = null
    let isAXS = false

    platforms.forEach((platform) => {
        if (!primaryMetaPlatform && (platform.type === 'Primary' || platform.type === 'Primary Others')) {
            primaryMetaPlatform = platform
        }

        if (!secondaryMetaPlatform && (platform.type === 'Secondary' || platform.type?.at(0) === 'Secondary')) {
            secondaryMetaPlatform = platform
        }

        if (!isAXS && platform.marketPlace === 'axs') {
            isAXS = true
        }
    })

    return { primaryMetaPlatform, secondaryMetaPlatform, isAXS }
}

const getCapacity = ({
    primaryMetaPlatform,
    secondaryMetaPlatform,
}: {
    primaryMetaPlatform: otherPlatform | null
    secondaryMetaPlatform: otherPlatform | null
}) => {
    return numberWithCommas(
        (() => {
            if (
                primaryMetaPlatform &&
                primaryMetaPlatform.type === 'Primary' &&
                primaryMetaPlatform.platform === 'ticketmaster' &&
                primaryMetaPlatform.totalPlaces > 99
            ) {
                return primaryMetaPlatform.totalPlaces
            }

            if (secondaryMetaPlatform?.totalPlaces) {
                return secondaryMetaPlatform.totalPlaces
            }

            if (
                primaryMetaPlatform &&
                primaryMetaPlatform.type === 'Primary Others' &&
                primaryMetaPlatform.totalPlaces
            ) {
                return primaryMetaPlatform.totalPlaces
            }
        })()
    )
}
