import type { FC } from 'react'
import type { Theme } from '@mui/material'
import { experimentalStyled } from '@mui/material/styles'
import type { SxProps } from '@material-ui/system'

interface LogoProps {
    sx?: SxProps<Theme>
}

const LogoRoot = experimentalStyled('svg')``

const Logo: FC<LogoProps> = (props) => (
    <LogoRoot width="250" height="250" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" version="1.1" {...props}>
        <g>
            <title>Layer 1</title>
            <g id="svg_1">
                <g id="svg_2">
                    <g id="svg_3">
                        <path
                            id="svg_4"
                            fill="#6369EB"
                            d="m86.7,85.2l-53.9,0c-3.4,0 -6.2,-2.8 -6.2,-6.2l0,-10.5l2.8,-0.8c3,-0.9 5.1,-4.2 5.1,-8c0,-3.9 -2.1,-7.2 -5.1,-8.1l-2.8,-0.8l0,-10.5c0,-3.4 2.8,-6.2 6.2,-6.2l53.9,0c3.4,0 6.2,2.8 6.2,6.2l0,10.5l-2.8,0.8c-3,0.9 -5.1,4.2 -5.1,8.1c0,3.8 2.1,7.1 5.1,8l2.8,0.8l0,10.5c0,3.4 -2.8,6.2 -6.2,6.2zm-52.3,-7.8l50.6,0l0,-3.4c-4.7,-2.7 -7.8,-8.2 -7.8,-14.3c0,-6.2 3.1,-11.7 7.8,-14.4l0,-3.4l-50.6,0l0,3.4c4.7,2.7 7.9,8.2 7.9,14.4c0,6.1 -3.1,11.6 -7.9,14.3l0,3.4z"
                            className="st0"
                        />
                    </g>
                    <g id="svg_5">
                        <path
                            id="svg_6"
                            fill="#6369EB"
                            d="m59.7,119.4c-32.9,0 -59.7,-26.8 -59.7,-59.7s26.8,-59.7 59.7,-59.7c32.9,0 59.7,26.8 59.7,59.7s-26.7,59.7 -59.7,59.7zm0,-111.7c-28.6,0 -51.9,23.3 -51.9,51.9s23.3,51.9 51.9,51.9c28.6,0 51.9,-23.3 51.9,-51.9s-23.2,-51.9 -51.9,-51.9z"
                            className="st0"
                        />
                    </g>
                    <g id="svg_7">
                        <g id="svg_8">
                            <path
                                id="svg_9"
                                fill="#6369EB"
                                d="m68.1,56.8l-16.7,0c-2.2,0 -3.9,-1.8 -3.9,-3.9c0,-2.2 1.8,-3.9 3.9,-3.9l16.7,0c2.2,0 3.9,1.8 3.9,3.9c0,2.2 -1.8,3.9 -3.9,3.9z"
                                className="st0"
                            />
                        </g>
                        <g id="svg_10">
                            <path
                                id="svg_11"
                                fill="#6369EB"
                                d="m68.1,70.2l-16.7,0c-2.2,0 -3.9,-1.8 -3.9,-3.9c0,-2.2 1.8,-3.9 3.9,-3.9l16.7,0c2.2,0 3.9,1.8 3.9,3.9c0,2.2 -1.8,3.9 -3.9,3.9z"
                                className="st0"
                            />
                        </g>
                    </g>
                </g>
                <g id="svg_12">
                    <g id="svg_13">
                        <path
                            id="svg_14"
                            fill="#6369EB"
                            d="m203.4,249.9c-11.8,0 -21.4,-9.6 -21.4,-21.4l0,-128.2c0,-11.8 9.6,-21.4 21.4,-21.4s21.4,9.6 21.4,21.4l0,128.2c0,11.8 -9.6,21.4 -21.4,21.4z"
                            className="st0"
                        />
                    </g>
                </g>
                <g id="svg_15">
                    <g id="svg_16">
                        <path
                            id="svg_17"
                            fill="#9862F0"
                            d="m136.4,249.9c-11.8,0 -21.4,-9.6 -21.4,-21.4l0,-85.4c0,-11.8 9.6,-21.4 21.4,-21.4s21.4,9.6 21.4,21.4l0,85.4c0,11.8 -9.6,21.4 -21.4,21.4z"
                            className="st1"
                        />
                    </g>
                </g>
                <g id="svg_18">
                    <g id="svg_19">
                        <path
                            id="svg_20"
                            fill="#D05BF5"
                            d="m69.3,249.9c-11.8,0 -21.4,-9.6 -21.4,-21.4l0,-53.2c0,-11.8 9.6,-21.4 21.4,-21.4s21.4,9.6 21.4,21.4l0,53.2c-0.1,11.8 -9.6,21.4 -21.4,21.4z"
                            className="st2"
                        />
                    </g>
                </g>
            </g>
        </g>
    </LogoRoot>
)

export default Logo
