export function CapitalizeFirstLetter(str: string) {
    return str.replace(/^\w/, (c) => c.toUpperCase())
}

export function hideCardNumber(cardNumber: number) {
    const str = cardNumber.toString()
    return str.slice(0, 4) + '*'.repeat(str.length - 5) + str.slice(-1)
}

export const validateUrlRegex =
    // eslint-disable-next-line no-useless-escape
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
