import useSettings from 'src/hooks/useSettings'
import { createCustomTheme } from 'src/theme'
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material'
import Rtl from '../../components/general/RTL'

interface Props {
    children?: React.ReactNode
}

export const ThemeProvider: React.FC<Props> = ({ children }) => {
    const { settings } = useSettings()

    const theme = createCustomTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        roundedCorners: settings.roundedCorners,
        theme: settings.theme,
    })

    return (
        <MuiThemeProvider theme={theme}>
            <Rtl direction={settings.direction}>
                <CssBaseline />
                {children}
            </Rtl>
        </MuiThemeProvider>
    )
}
