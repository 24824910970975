import axiosClient from 'src/utils/axios'
import dayjs from 'dayjs'
import { FrontEndError } from 'src/utils/error'

interface IUpdatePresaleCode {
    eventId: string
    presaleName: string
    startDateTime: string
    endDateTime?: string
    code: string
}

class OverViewServiceAPI {
    async getPresales({
        page,
        perPage,
        valueInput,
        filtringState,
        categories,
        searchParams,
        isDisabledFilters,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
    }) {
        try {
            const params = {
                per_page: perPage,
                category: categories.join(','),
                page,
                saleType: filtringState.eventSelect.join(','),
                date: searchParams.get('date')
                    ? dayjs(searchParams.get('date')).format('YYYY-MM-DD')
                    : dayjs(filtringState.valueDate).format('YYYY-MM-DD'),
                search: valueInput,
                countryCode: filtringState.countries.join(','),
                platform: filtringState.platformSelect.join(','),
                spotifyMin,
                spotifyMax,
                capacityMin,
                capacityMax,
                time: filtringState.timeSelect,
            }

            if (params.date === 'Invalid Date') delete params.date

            if (params.time.length === 0) delete params.time

            if (!filtringState) {
                delete params.spotifyMin
                delete params.spotifyMax
                delete params.capacityMin
                delete params.capacityMax
                delete params.category
                delete params.date
                delete params.time
            }

            return await axiosClient().get('/api/presales/index', { params })
        } catch (err) {
            FrontEndError(err)
        }
    }

    async getCategories() {
        return await axiosClient().get('/api/events/get-categories')
    }

    async postBuyingList(eventId: string, note: string) {
        try {
            axiosClient().post('/api/user/buying-list', {
                event_id: eventId,
                notes: note,
            })
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async updatePresale({ eventId, presaleName, startDateTime, endDateTime, code }: IUpdatePresaleCode) {
        const url = '/api/presales/save-presale-code'
        const update = {
            eventId,
            presaleName,
            startDateTime,
            ...(endDateTime ? { endDateTime } : {}),
            ...(code ? { code } : {}),
        }
        try {
            await axiosClient().post(url, update)
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getPresaleCodes() {
        const url = '/api/presales/get-presale-codes'
        try {
            const res = await axiosClient().get(url)
            return res.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }
}

export const PresalesAPI = new OverViewServiceAPI()
