import { useState, useCallback } from "react";
import React from "react";
interface Modals {
  [modalId: string]: boolean;
}

interface ModalFunctions {
  changeModal: (modalId: string) => void;
  isModalOpen: (modalId: string) => boolean;
  handleChangeModal: (event: React.MouseEvent<HTMLElement>, modalId: string, secondModalId?: string) => void;
  modals: {}
}

const useModal = (): ModalFunctions => {
  const [modals, setModals] = useState<Modals>({});

  const changeModal = useCallback((modalId: string) => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalId]: !prevModals[modalId],
    }));
  }, []);

  const isModalOpen = useCallback(
    (modalId: string) => {
      return modals[modalId] || false;
    },
    [modals],
  );

  const handleChangeModal = useCallback(
    (event: React.MouseEvent<HTMLElement>, modalId: string, secondModalId?: string) => {
      if (event.target === event.currentTarget) {
        changeModal(modalId);
        if (secondModalId) changeModal(secondModalId);
      }
    },
    [changeModal],
  );

  return {
    changeModal,
    isModalOpen,
    handleChangeModal,
    modals
  };
};

export default useModal;
