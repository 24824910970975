import axios from 'axios'

export const BASE_URL = process.env.REACT_APP_BASE_URL

export function axiosClient() {
    let defaultOptions = {
        baseURL: BASE_URL,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')}`,
        },
    }

    let instance = axios.create(defaultOptions)

    instance.interceptors.request.use(function (config) {
        config.headers.common = {
            Authorization: `Bearer ${localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')}`,
        }
        return config
    })
    instance.interceptors.response.use(
        function (response) {
            if (!navigator.onLine) {
                // Navigate to the no connection page
                window.location.href = '/no-connection'
                return Promise.reject(new Error('User is offline'))
            }
            return response
        },
        function (error) {
            if (error.response && error.response.status === 502) {
                // Navigate to the maintenance page
                window.location.href = '/maintenance'
            }
            if (!navigator.onLine) {
                // Navigate to the no connection page
                window.location.href = '/no-connection'
            }
            return Promise.reject(error)
        }
    )

    return instance
}

export let instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')}`,
        'Content-Type': 'application/json',
    },
})

export default axiosClient
