import { Box, Button, Typography } from '@mui/material'

export const SecondSearchInfoModal = ({ changeModal }) => {
    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '550px',
                gap: '20px',
                zIndex: '2500',
            }}
        >
            <Box>
                <Typography sx={{ mx: 'auto', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>
                    Search Tips
                </Typography>
                <Box
                    sx={{
                        boxShadow: '0px 2px 11px -2px rgba(0,0,0,0.1);',
                        p: '10px',
                        mt: '6px',
                        bgcolor: '#ECF0F1',
                        borderRadius: '10px',
                    }}
                >
                    <Typography sx={{ mx: 'auto', fontSize: '15px', textAlign: 'start' }}>
                        Use the second search bar for a more accurate search. For example, first search "Sabrina
                        Spencer," then add "New York" in the second bar to find all her shows in New York, or search for
                        a sports team and add their home city in the second bar.
                    </Typography>
                </Box>
            </Box>

            <Button
                type="submit"
                onClick={() => {
                    changeModal('secondInfoModal')
                }}
                sx={{ width: '80px', bgcolor: 'black', ':hover': { bgcolor: 'black' } }}
            >
                Close
            </Button>
        </Box>
    )
}
