import CheckBoxIcon from '@mui/icons-material/CheckBox'
import FilterListIcon from '@mui/icons-material/FilterList'
import {
    Backdrop,
    Box,
    Button,
    FormControl,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    Slide,
    Snackbar,
    Typography,
    styled,
} from '@mui/material'
import type { FC } from 'react'
import React, { SetStateAction, useEffect, useState } from 'react'
import { DirectLinks } from 'src/components/dashboard/eventAnalytics/directLinks'
import { AvatarModal } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModals/AvatarModal'
import { PresaleInformationModal } from 'src/components/dashboard/overview/PresaleInformationModal'
import axiosClient from 'src/utils/axios'

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async'
import { EventSelectArray, countries, platformSelectArray, timeSelectArray } from 'src/common/constants/overview'
import { GET_CATEGORIES_QUERY_KEY, GET_WATCHLIST } from 'src/common/constants/queryKeys'
import { SavingModal } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModals/SavingModal'
import PaginationBottomComponent from 'src/components/dashboard/generalComponents/StyledPagination'
import { StyledCheckBox, StyledMenuItem } from 'src/components/dashboard/generalComponents/TableCoponents'
import { CustomSwitch } from 'src/components/general/CustomSwitch'
import DebouncedInput from 'src/components/general/DebouncedInput'
import { StyledSelectWithBorder } from 'src/components/general/StyledSelect'
import { useBuyingList } from 'src/hooks/useBuyingList'
import SearchIcon from 'src/icons/SearchIcon'
import { PresalesAPI } from 'src/services/presalesAPI'
import { WatchListAPI } from 'src/services/watchListAPI'
import { WatchlistTable } from 'src/components/dashboard/watchlist'
import { StyledDatePicker } from 'src/components/general/StyledDatePicker'
import { NoteModal } from 'src/components/dashboard/eventAnalytics/NoteModal'
import { FrontEndError } from 'src/utils/error'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { FirstSearchInfoModal } from 'src/components/dashboard/eventAnalytics/eventAnalyticsModals/FirstSearchInfoModal'

const WatchList: FC = () => {
    const {
        page,
        perPage,
        allEntries,
        valueInput,
        selectedItem,
        sliderValue,
        selectedMeta,
        filterShow,
        filtringState,
        popupText,
        searchParams,
        setFilterShow,
        setSelectedMeta,
        setPopupText,
        setSelectedItem,
        setSearchParams,
        setFilteringState,
        handleChangePage,
        handleChangePerPage,
        setAllEntries,
        handleChangeInput,
        isDisabledFilters,
    } = useBuyingList()

    const handleDisableAll = () => {
        setFilteringState({
            categories: ['Music'],
            countries: [],
            platformSelect: ['all'],
            eventSelect: ['all_presales'],
            timeSelect: '',
            valueDate: new Date(),
        })
    }

    const handleCategoryChange = (event: any) => {
        const selectedOptions = event.target.value as string[]
        if (selectedOptions.includes('All')) {
            setFilteringState((prev) => ({
                ...prev,
                categories: [...(dataCategories?.data.data ? dataCategories?.data.data : selectedOptions)],
            }))
            return
        }
        if (selectedOptions.includes('Unselect_All')) {
            setFilteringState((prev) => ({
                ...prev,
                categories: [],
            }))
            return
        }
        setFilteringState((prev) => ({ ...prev, categories: selectedOptions }))
    }
    const handleCountryChange = (event: any) => {
        const selectedOptions = event.target.value as string[]
        if (selectedOptions.includes('All')) {
            setFilteringState((prev) => ({
                ...prev,
                countries: countries,
            }))
            return
        }
        if (selectedOptions.includes('Unselect_All')) {
            setFilteringState((prev) => ({
                ...prev,
                countries: [],
            }))
            return
        }

        setFilteringState((prev) => ({ ...prev, countries: selectedOptions }))
    }

    const handlePlatformChange = (event: any) => {
        const selectedOptions = event.target.value as string[]
        if (selectedOptions.includes('All')) {
            setFilteringState((prev) => ({
                ...prev,
                platformSelect: platformSelectArray.map((elem) => elem.name),
            }))
            return
        }
        if (selectedOptions.includes('Unselect_All')) {
            setFilteringState((prev) => ({
                ...prev,
                platformSelect: [],
            }))
            return
        }
        setFilteringState((prev) => ({ ...prev, platformSelect: selectedOptions }))
    }
    const handleEventChange = (event: any) => {
        const selectedOptions = event.target.value as string[]
        if (selectedOptions.includes('All')) {
            setFilteringState((prev) => ({
                ...prev,
                eventSelect: EventSelectArray.map((elem) => elem.name),
            }))
            return
        }
        if (selectedOptions.includes('Unselect_All')) {
            setFilteringState((prev) => ({
                ...prev,
                eventSelect: [],
            }))
            return
        }
        setFilteringState((prev) => ({ ...prev, eventSelect: selectedOptions }))
        setSearchParams('')
    }
    const handleChangeTime = (event: any) => {
        setFilteringState((prev) => ({
            ...prev,
            timeSelect: event.target.value as string,
        }))
        setSearchParams('')
    }

    const setFilterState = () => {
        setFilterShow((filterShow) => !filterShow)
    }

    const handleSetDate = (value: Date, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setFilteringState((prev) => ({
            ...prev,
            valueDate: value,
        }))

        setSearchParams('')
    }

    const handleAddToBuying = async (eventId: string, note?: string) => {
        await PresalesAPI.postBuyingList(eventId, note)
        setPopupText('Event Saved')
    }

    const [categories, setCategories] = useState([])

    useEffect(() => {
        setCategories(filtringState.categories.map((str) => (str.includes('&') ? str.replace('&', '%26') : str)))
    }, [filtringState])

    const { data: dataWatchlist } = useQuery({
        queryKey: [
            GET_WATCHLIST,
            perPage,
            page,
            categories,
            valueInput,
            filtringState,
            isDisabledFilters,
            sliderValue,
            searchParams.toString(),
        ],
        queryFn: () =>
            WatchListAPI.getWatchlist({
                perPage,
                page,
                categories,
                valueInput,
                filtringState,
                searchParams,
                isDisabledFilters,
                spotifyMin: sliderValue.spotifyRankValue[0],
                spotifyMax: sliderValue.spotifyRankValue[1],
                capacityMin: sliderValue.capacityValue[0],
                capacityMax: sliderValue.capacityValue[1],
                priceMin: sliderValue.priceValue[0],
                priceMax: sliderValue.priceValue[1],
                filterShow,
            }),
    })

    const handleSubmitNoteUpdate = async ({ eventId, note }) => {
        await handleEdit(eventId, note)
        try {
            setPopupText('Event notes updated')
        } catch (e) {}
    }

    const handleEdit = (eventId: string, note: string) => {
        axiosClient()
            .post('/api/user/buying-list', {
                event_id: eventId,
                notes: note,
            })
            .then((resp) => setEdit(true))
            .catch((err) => FrontEndError(err))
            .finally(() =>
                queryClient.invalidateQueries({
                    queryKey: [
                        GET_WATCHLIST,
                        perPage,
                        page,
                        categories,
                        valueInput,
                        filtringState,
                        isDisabledFilters,
                        sliderValue,
                        searchParams.toString(),
                    ],
                })
            )
    }

    useEffect(() => {
        if (dataWatchlist) {
            // Set Total Events
            setAllEntries(dataWatchlist.total_results)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataWatchlist])

    useEffect(() => {
        handleChangePage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [perPage, categories, valueInput, filtringState, isDisabledFilters, sliderValue, searchParams])

    const [selectedNote, setSelectedNote] = useState()
    const [openPresale, setOpenPresale] = useState(false)
    const [openDirectLinks, setOpenDirectLinks] = useState(false)
    const [openSaveModal, setOpenSaveModal] = useState(false)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [edit, setEdit] = useState(false)
    const [noteModal, setNoteModal] = useState(false)
    const [avatarModal, setAvatarModal] = useState(false)
    const [infoModal, setInfoModal] = useState(false)

    const handleClickModalClose = (
        event: React.MouseEvent<HTMLElement>,
        setOpen: React.Dispatch<SetStateAction<boolean>>
    ) => {
        if (event.target === event.currentTarget) {
            setOpen(false)
        }
    }

    const { data: dataCategories } = useQuery({
        queryKey: [GET_CATEGORIES_QUERY_KEY],
        queryFn: PresalesAPI.getCategories,
    })

    const queryClient = useQueryClient()

    const handleDelete = (eventId: string) => {
        axiosClient()
            .delete(`api/admin/watchlist/${eventId}`)
            .then(() => {
                setPopupText('Event Deleted From Watchlist')
            })
            .catch((err) => FrontEndError(err))
            .finally(() =>
                queryClient.invalidateQueries({
                    queryKey: [
                        GET_WATCHLIST,
                        perPage,
                        page,
                        categories,
                        valueInput,
                        filtringState,
                        isDisabledFilters,
                        sliderValue,
                        searchParams.toString(),
                    ],
                })
            )
    }

    return (
        <>
            <Helmet>
                <title>Watchlist | Ticketmetric</title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    py: '25px',
                    px: '12px',
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#F7F6F4',
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        py: '57px',
                        px: '51px',
                        height: '100%',
                        boxShadow: 0,
                        borderRadius: 0,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography sx={{ textWrap: 'nowrap', mr: '32px' }} variant="h5">
                            Watchlist
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Box
                                sx={{
                                    borderRadius: '8px',
                                    padding: '4px 12px',
                                    border: 'solid',
                                    borderWidth: '1px',
                                    borderColor: '#E9D7FE',
                                    mr: '8px',
                                }}
                            >
                                <Typography sx={{ color: '#AE763F' }} noWrap={true}>
                                    {allEntries} events
                                </Typography>
                            </Box>
                            <IconButton
                                sx={{ width: '26px', height: '26px', mr: '4px' }}
                                onClick={() => setInfoModal(true)}
                            >
                                <HelpOutlineOutlinedIcon sx={{ width: '20px', height: '20px', color: '#667085' }} />
                            </IconButton>
                            <Paper
                                component="form"
                                sx={{
                                    border: '1px solid',
                                    borderColor: '#D0D5DD',
                                    borderRadius: '8px',
                                    p: '3px 14px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    boxShadow: 0,
                                    mr: '18px',
                                }}
                            >
                                <SearchIcon fontSize="small" sx={{ color: '#667085', fontSize: '20px', mr: '8px' }} />
                                <DebouncedInput
                                    value={valueInput}
                                    onSearch={handleChangeInput}
                                    sx={{ fontSize: '16px', lineHeight: '24px', p: '0', width: '100%' }}
                                    placeholder="Search"
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                    debounceTime={300}
                                />
                            </Paper>
                            <Box
                                sx={{
                                    border: '1px solid',
                                    borderColor: '#D0D5DD',
                                    borderRadius: '8px',
                                    p: '9px 7.5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '8px',
                                }}
                            >
                                <FilterListIcon fontSize="small" />
                                <Typography
                                    sx={{ fontSize: '16px', fontWeight: '500', lineHeight: '20px' }}
                                    noWrap={true}
                                >
                                    Show Filters
                                </Typography>
                                <CustomSwitch checked={filterShow} onChange={setFilterState} checkedcolor="#204391" />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        {filterShow && (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: '15px',
                                        justifyContent: 'end',
                                        mt: '40px',
                                    }}
                                >
                                    <StyledDatePicker date={filtringState.valueDate} handleSetDate={handleSetDate} />

                                    <FormControl sx={{ minWidth: '141px', borderRadius: '8px' }}>
                                        <StyledInputLabel
                                            shrink={true}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                top: '-5px',
                                            }}
                                        >
                                            Platform
                                        </StyledInputLabel>
                                        <StyledSelectWithBorder
                                            label="Platform"
                                            multiple
                                            value={filtringState.platformSelect}
                                            onChange={handlePlatformChange}
                                            renderValue={(selected: Array<any>) =>
                                                selected.map((elem) => elem[0].toUpperCase() + elem.slice(1)).join(', ')
                                            }
                                            sx={{ maxWidth: '139px' }}
                                        >
                                            {filtringState.platformSelect.length === platformSelectArray.length ? (
                                                <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={
                                                            filtringState.platformSelect.length ===
                                                            platformSelectArray.length
                                                        }
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'Unselect All'} />
                                                </StyledMenuItem>
                                            ) : (
                                                <StyledMenuItem key={'All'} value={'All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={
                                                            filtringState.platformSelect.length ===
                                                            platformSelectArray.length
                                                        }
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'All'} />
                                                </StyledMenuItem>
                                            )}
                                            {platformSelectArray.map(({ name, tag }) => (
                                                <StyledMenuItem key={name} value={name}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={filtringState.platformSelect.indexOf(name) > -1}
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={tag} />
                                                </StyledMenuItem>
                                            ))}
                                        </StyledSelectWithBorder>
                                    </FormControl>

                                    <FormControl sx={{ minWidth: '141px', maxWidth: '141px', borderRadius: '8px' }}>
                                        <StyledInputLabel
                                            shrink={true}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                top: '-5px',
                                            }}
                                        >
                                            Sale
                                        </StyledInputLabel>
                                        <StyledSelectWithBorder
                                            label="Sale"
                                            multiple
                                            value={filtringState.eventSelect}
                                            onChange={handleEventChange}
                                            renderValue={(selected: Array<any>) =>
                                                selected
                                                    .map((elem) => elem[0].toUpperCase() + elem.slice(1))
                                                    .join(', ')
                                                    .replace('_', ' ')
                                            }
                                        >
                                            {filtringState.eventSelect.length === EventSelectArray.length ? (
                                                <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={
                                                            filtringState.eventSelect.length === EventSelectArray.length
                                                        }
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'Unselect All'} />
                                                </StyledMenuItem>
                                            ) : (
                                                <StyledMenuItem key={'All'} value={'All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={
                                                            filtringState.eventSelect.length === EventSelectArray.length
                                                        }
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'All'} />
                                                </StyledMenuItem>
                                            )}
                                            {EventSelectArray.map((item) => (
                                                <StyledMenuItem
                                                    sx={{ whiteSpace: 'normal', mb: '4px' }}
                                                    key={item.tag}
                                                    value={item.name}
                                                >
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={filtringState.eventSelect.indexOf(item.name) > -1}
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <Typography
                                                        sx={{
                                                            width: '100px',
                                                            fontSize: '14px',
                                                            wordWrap: 'break-word',
                                                        }}
                                                    >
                                                        {item.tag}
                                                    </Typography>
                                                </StyledMenuItem>
                                            ))}
                                        </StyledSelectWithBorder>
                                    </FormControl>

                                    <FormControl sx={{ minWidth: '141px', maxWidth: '141px', borderRadius: '8px' }}>
                                        <StyledInputLabel
                                            shrink={true}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                top: '-5px',
                                            }}
                                        >
                                            Time
                                        </StyledInputLabel>
                                        <StyledSelectWithBorder
                                            label="Time"
                                            value={filtringState.timeSelect}
                                            onChange={handleChangeTime}
                                        >
                                            {timeSelectArray.map((item) => (
                                                <MenuItem
                                                    sx={{
                                                        whiteSpace: 'normal',
                                                        ':hover': {
                                                            backgroundColor: 'lightgray',
                                                        },
                                                        '&.Mui-selected': {
                                                            ':hover': {
                                                                backgroundColor: 'lightgray',
                                                            },
                                                        },
                                                    }}
                                                    key={item.tag}
                                                    value={item.name}
                                                >
                                                    <Typography
                                                        sx={{
                                                            width: '110px',
                                                            fontSize: '14px',
                                                            wordWrap: 'break-word',
                                                        }}
                                                    >
                                                        {item.tag}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </StyledSelectWithBorder>
                                    </FormControl>

                                    <FormControl sx={{ minWidth: '141px', maxWidth: '141px', borderRadius: '8px' }}>
                                        <StyledInputLabel
                                            shrink={true}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                top: '-5px',
                                            }}
                                        >
                                            Category
                                        </StyledInputLabel>
                                        <StyledSelectWithBorder
                                            label="Platform"
                                            multiple
                                            value={filtringState.categories}
                                            onChange={handleCategoryChange}
                                            renderValue={(selected: Array<any>) => selected.join(', ')}
                                        >
                                            {filtringState.categories.length === dataCategories?.data.data.length ? (
                                                <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={
                                                            filtringState.categories.length ===
                                                            dataCategories?.data.data.length
                                                        }
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'Unselect All'} />
                                                </StyledMenuItem>
                                            ) : (
                                                <StyledMenuItem key={'All'} value={'All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={
                                                            filtringState.categories.length ===
                                                            dataCategories?.data.data.length
                                                        }
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'All'} />
                                                </StyledMenuItem>
                                            )}

                                            {dataCategories?.data.data.map((cat) => (
                                                <StyledMenuItem key={cat} value={cat}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={filtringState.categories.indexOf(cat) > -1}
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={cat} />
                                                </StyledMenuItem>
                                            ))}
                                        </StyledSelectWithBorder>
                                    </FormControl>
                                    <FormControl sx={{ minWidth: '141px', maxWidth: '141px', borderRadius: '8px' }}>
                                        <StyledInputLabel
                                            shrink={true}
                                            sx={{
                                                color: '#000000',
                                                fontSize: '16px',
                                                fontWeight: '500',
                                                top: '-5px',
                                            }}
                                        >
                                            Country
                                        </StyledInputLabel>
                                        <StyledSelectWithBorder
                                            label="Platform"
                                            multiple
                                            value={filtringState.countries}
                                            onChange={handleCountryChange}
                                            renderValue={(selected: Array<any>) => selected.join(', ')}
                                        >
                                            {filtringState.countries.length === countries.length ? (
                                                <StyledMenuItem key={'Unselect_All'} value={'Unselect_All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={filtringState.countries.length === countries.length}
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'Unselect All'} />
                                                </StyledMenuItem>
                                            ) : (
                                                <StyledMenuItem key={'All'} value={'All'}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checked={filtringState.countries.length === countries.length}
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                    />
                                                    <ListItemText primary={'All'} />
                                                </StyledMenuItem>
                                            )}
                                            {countries.map((cat) => (
                                                <StyledMenuItem key={cat} value={cat}>
                                                    <StyledCheckBox
                                                        sx={{ color: '#000000' }}
                                                        checkedIcon={<CheckBoxIcon sx={{ color: '#000000' }} />}
                                                        checked={filtringState.countries.indexOf(cat) > -1}
                                                    />
                                                    <ListItemText primary={cat} />
                                                </StyledMenuItem>
                                            ))}
                                        </StyledSelectWithBorder>
                                    </FormControl>
                                    <Button
                                        sx={{
                                            backgroundColor: '#204391',
                                            minWidth: '141px',
                                            height: '44px',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                backgroundColor: '#204391',
                                                boxShadow: 'none',
                                            },
                                        }}
                                        onClick={handleDisableAll}
                                        variant="contained"
                                    >
                                        <Typography noWrap={true} sx={{ color: '#FFFFFF', fontWeight: '500' }}>
                                            Clear Filters
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Paper>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        py: '10px',
                        height: '100%',
                    }}
                >
                    {dataWatchlist?.data.length ? (
                        <WatchlistTable
                            setOpenSaveModal={setOpenSaveModal}
                            setOpenAvatar={setAvatarModal}
                            watchlistTableData={dataWatchlist}
                            setOpenDirectLinks={setOpenDirectLinks}
                            setOpenPresale={setOpenPresale}
                            setSelectedItem={setSelectedItem}
                            setSelectedMeta={setSelectedMeta}
                            handleDelete={handleDelete}
                            handleAddToBuying={handleAddToBuying}
                            setNoteModal={setNoteModal}
                            setSelectedNote={setSelectedNote}
                        ></WatchlistTable>
                    ) : null}

                    <Backdrop
                        style={{ zIndex: '6' }}
                        open={openPresale}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setOpenPresale)}
                    >
                        <PresaleInformationModal item={selectedItem} setOpen={setOpenPresale} timezone={'local'} />
                    </Backdrop>
                    <Backdrop
                        style={{ zIndex: '6' }}
                        open={openDirectLinks}
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleClickModalClose(event, setOpenDirectLinks)
                        }
                    >
                        <DirectLinks meta={selectedMeta} setOpen={setOpenDirectLinks} />
                    </Backdrop>
                    <Backdrop
                        open={noteModal}
                        style={{ zIndex: '6' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setNoteModal)}
                    >
                        <NoteModal
                            label="Add note"
                            buttonLabel="Add"
                            setOpen={setNoteModal}
                            selectedNote={selectedNote}
                            eventId={selectedItem?._id}
                            onSubmit={({ eventId, note }) => handleSubmitNoteUpdate({ eventId, note })}
                            setPopupText={setPopupText}
                        ></NoteModal>
                    </Backdrop>
                    <Backdrop
                        style={{ zIndex: '6' }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setAvatarModal)}
                        open={avatarModal}
                    >
                        <AvatarModal
                            artists={selectedItem?.artistsStat}
                            setOpen={setAvatarModal}
                            artistId={selectedItem?._id}
                        />
                    </Backdrop>
                    <Backdrop style={{ zIndex: '6' }} open={openSaveModal} onClick={() => setOpenSaveModal(false)}>
                        <SavingModal
                            setPopupText={setPopupText}
                            event_id={selectedItem?._id}
                            setState={() => setOpenSaveModal(false)}
                        />
                    </Backdrop>
                    <Backdrop
                        style={{ zIndex: '6' }}
                        open={infoModal}
                        onClick={(event: React.MouseEvent<HTMLElement>) => handleClickModalClose(event, setInfoModal)}
                    >
                        <FirstSearchInfoModal
                            changeModal={() => {
                                setInfoModal((e) => !e)
                            }}
                        />
                    </Backdrop>
                    <PaginationBottomComponent
                        count={Math.ceil(allEntries / perPage)}
                        perPage={perPage}
                        page={page}
                        setPage={handleChangePage}
                        setPerPage={handleChangePerPage}
                    />
                </Box>
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={popupText !== ''}
                autoHideDuration={3000}
                TransitionComponent={Slide}
                onClose={() => {
                    setPopupText('')
                }}
                sx={{ '& .MuiSnackbarContent-root': { minWidth: '100px' } }}
                message={popupText}
            />
        </>
    )
}

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
    '&.Mui-focused': {
        color: 'black',
    },
}))
export default WatchList
