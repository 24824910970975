import createSvgIcon from '@mui/material/utils/createSvgIcon'

const SpotifyIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g id="Spotify" clipPath="url(#clip0_789_13595)">
            <g id="Spotify_2">
                <path
                    id="Subtract"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 7.99986C0 3.5817 3.58179 0 7.99995 0C12.4183 0 16 3.5817 16 7.99986C16 12.4185 12.4183 16 7.99995 16C3.58179 16 0 12.4185 0 7.99986ZM12.5385 6.98502C9.99861 5.47667 5.80916 5.33798 3.38456 6.07386C2.99512 6.19203 2.58338 5.97224 2.46549 5.58289C2.3475 5.19327 2.56701 4.78181 2.95664 4.66345C5.74001 3.81861 10.3668 3.98177 13.2907 5.71744C13.641 5.92538 13.7558 6.37767 13.5483 6.72731C13.3405 7.07751 12.8878 7.19296 12.5385 6.98502ZM11.6094 9.42186C11.8981 9.59941 12.2762 9.50871 12.4544 9.21957C12.6319 8.93109 12.541 8.55285 12.2523 8.37502C9.82024 6.8804 6.2626 6.45982 3.40086 7.32827C3.07653 7.42706 2.89333 7.76955 2.99119 8.09435C3.08989 8.41867 3.43303 8.60177 3.75783 8.50364C6.26298 7.7432 9.49196 8.12032 11.6094 9.42186ZM10.8154 11.5283C11.0467 11.6698 11.3491 11.5971 11.4907 11.3649C11.6322 11.1334 11.5593 10.8309 11.3277 10.6895C9.25085 9.42041 6.67627 9.12515 3.6748 9.81125C3.40994 9.87157 3.2449 10.135 3.3055 10.3993C3.36571 10.6635 3.62917 10.8291 3.89346 10.7685C6.63619 10.1421 8.9651 10.3974 10.8154 11.5283Z"
                    fill="#1ED760"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_789_13595">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>,
    'Search',
)

export default SpotifyIcon
