import { Box, Button, ButtonGroup, InputBase } from '@mui/material'
import React, { SetStateAction, useEffect, useState } from 'react'
import { ModalHeader } from '../generalComponents/ModalHeader'

export function NoteModal({
    setOpen,
    selectedNote = '',
    eventId,
    onSubmit,
    label = 'Add New Note',
    isSecondary,
    setPopupText,
    showDestination,
}: {
    selectedNote?: string
    setOpen: React.Dispatch<SetStateAction<boolean>>
    eventId?: string
    isSecondary?: boolean
    onSubmit: ({ eventId, note, isSecondary }: { eventId: string; note: string; isSecondary: boolean }) => void
    label?: string
    buttonLabel?: string
    setPopupText?: (value: string) => void
    showDestination?: boolean
}) {
    const [textArea, setTextArea] = useState(selectedNote)

    const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTextArea(e.target.value)
    }
    const handleAdd = async (isSecondary: boolean) => {
        if (textArea && textArea?.length > 0) {
            onSubmit({ eventId, note: textArea, isSecondary })
            setTextArea('')
            setOpen(false)
            if (setPopupText) setPopupText(`New Note Added to ${isSecondary ? 'Secondary' : 'Primary'} Favs. List`)
        }
    }

    const handleDelete = () => {
        onSubmit({ eventId, note: '', isSecondary })
        setTextArea('')
        setOpen(false)
    }

    useEffect(() => {
        setTextArea(selectedNote)
    }, [selectedNote])

    const closeNoteModal = (isOpen: boolean) => {
        setOpen(isOpen)
        setTextArea('')
    }

    return (
        <Box
            sx={{
                bgcolor: 'background.white',
                p: '25px',
                borderRadius: '12px',

                flexDirection: 'column',
                width: '550px',
                gap: '20px',
                zIndex: '1500',

                display: 'flex',
            }}
        >
            <ModalHeader label={label} type="all" setOpen={closeNoteModal}></ModalHeader>
            <Box>
                <InputBase
                    multiline
                    rows={6}
                    sx={{
                        width: '100%',
                        minHeight: '100px',
                        paddingInline: '20px',
                        border: 1,
                        borderColor: 'grey.400',
                        backgroundColor: 'white',
                        borderRadius: '6px',
                    }}
                    value={textArea}
                    onChange={handleChangeInput}
                ></InputBase>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    width: '100%',
                    gap: '12px',
                }}
            >
                {!showDestination ? (
                    <Button
                        disabled={textArea && textArea.length === 0}
                        onClick={() => handleAdd(isSecondary)}
                        sx={{
                            bgcolor: 'background.black',
                            color: 'text.default',
                            '&:hover': { bgcolor: 'background.black' },
                            '&:disabled': { color: '#aeaeae', backgroundColor: '#e5e5e5' },
                            px: '16px',
                            py: '8px',
                        }}
                    >
                        Save
                    </Button>
                ) : (
                    <ButtonGroup>
                        <Button
                            disabled={textArea && textArea.length === 0}
                            onClick={() => handleAdd(false)}
                            sx={{
                                bgcolor: '#3164F4',
                                color: 'text.default',
                                '&:hover': { bgcolor: 'background.black' },
                                '&:disabled': { color: '#aeaeae', backgroundColor: '#e5e5e5' },
                                px: '16px',
                                py: '8px',
                            }}
                        >
                            Save to Primary
                        </Button>
                        <Button
                            disabled={textArea && textArea.length === 0}
                            onClick={() => handleAdd(true)}
                            sx={{
                                bgcolor: '#0D59AB',
                                color: 'text.default',
                                '&:hover': { bgcolor: 'background.black' },
                                '&:disabled': { color: '#aeaeae', backgroundColor: '#e5e5e5' },
                                px: '16px',
                                py: '8px',
                            }}
                        >
                            Save to Secondary
                        </Button>
                    </ButtonGroup>
                )}

                {selectedNote && selectedNote?.length > 0 && (
                    <Button
                        sx={{
                            bgcolor: '#E72929',
                            color: 'text.default',
                            '&:hover': { bgcolor: 'red' },
                            px: '16px',
                            py: '8px',
                        }}
                        onClick={() => handleDelete()}
                    >
                        DELETE NOTE
                    </Button>
                )}
            </Box>
        </Box>
    )
}
