import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

interface Props {
    children?: React.ReactNode
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

export const StripeProvider: React.FC<Props> = ({ children }) => {
    return <Elements stripe={stripePromise}>{children}</Elements>
}
