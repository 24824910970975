import * as Sentry from '@sentry/react'
import { FallbackRender } from 'src/components/error'

interface Props {
    children?: React.ReactNode
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [Sentry.replayIntegration()],

    tracesSampleRate: 1.0,

    tracePropagationTargets: ['localhost', /^https:\/\/api-staging.tickets-insider\.com\/api/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

export const SentryProvider: React.FC<Props> = ({ children }) => {
    return <Sentry.ErrorBoundary fallback={<FallbackRender />}>{children}</Sentry.ErrorBoundary>
}
