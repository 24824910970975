import merge from 'lodash/merge'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import type { Direction, Theme, ThemeOptions } from '@mui/material'
import { THEMES } from '../constants'
import { lightShadows, darkShadows } from './shadows'

interface ThemeConfig {
    direction?: Direction
    responsiveFontSizes?: boolean
    roundedCorners?: boolean
    theme?: string
}

const baseOptions: ThemeOptions = {
    direction: 'ltr',
    components: {
        MuiAvatar: {
            styleOverrides: {
                fallback: {
                    height: '75%',
                    width: '75%',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box',
                },
                html: {
                    MozOsxFontSmoothing: 'grayscale',
                    WebkitFontSmoothing: 'antialiased',
                    // height: '100%',
                    // width: '100%',
                },
                body: {
                    height: '100%',
                },
                '#root': {
                    height: '100%',
                },
                '#nprogress .bar': {
                    zIndex: '2000 !important',
                },
            },
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h6',
                },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
    },
    typography: {
        button: {
            fontWeight: 600,
        },
        h1: {
            fontWeight: 600,
            fontSize: '3.5rem',
        },
        h2: {
            fontWeight: 600,
            fontSize: '3rem',
        },
        h3: {
            fontWeight: 600,
            fontSize: '2.25rem',
        },
        h4: {
            fontWeight: 600,
            fontSize: '2rem',
        },
        h5: {
            fontWeight: 600,
            fontSize: '1.5rem',
        },
        h6: {
            fontWeight: 600,
            fontSize: '1.125rem',
        },
        overline: {
            fontWeight: 600,
        },
    },
}

const themesOptions = {
    [THEMES.LIGHT]: {
        components: {
            MuiInputBase: {
                styleOverrides: {
                    input: {
                        '&::placeholder': {
                            opacity: 0.86,
                            color: '#42526e',
                        },
                    },
                },
            },
        },
        palette: {
            action: {
                active: '#6b778c',
            },
            background: {
                default: '#F7F6F4',
                primary: '#F7F8F9',
                paper: '#ffffff',
                transparent: '#FFFFFF88',
            },
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'light',
            primary: {
                contrastText: '#ffffff',
                main: '#5664d2',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#172b4d',
                secondary: '#6b778c',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: lightShadows,
    },
    [THEMES.DARK]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#F7F6F4',
                primary: '#F7F8F9',
                paper: '#FFFFFF',
                layout: '#2A3042',
                white: '#FFFFFF',
                black: '#000000',
                blue: '#3164F4',
                transparent: '#FFFFFF88',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#000000',
                main: '#EAEAEA',
            },
            action: {
                active: '#2F2F2F',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#000000',
                secondary: '#214173',
                default: '#FFFFFF',
                contrast: '#3F4254',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
            grey: {
                100: '#E0E0E0',
                400: '#B5B5C3',
                500: '#959CB6',
                600: '#A3A3A3',
                700: '#F3F6F9',
                800: '#7A7A7A',
            },
        },
        shadows: darkShadows,
    },
    [THEMES.NATURE]: {
        components: {
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                    },
                },
            },
        },
        palette: {
            background: {
                default: '#1c2531',
                paper: '#293142',
                transparent: '#FFFFFF88',
            },
            divider: 'rgba(145, 158, 171, 0.24)',
            error: {
                contrastText: '#ffffff',
                main: '#f44336',
            },
            mode: 'dark',
            primary: {
                contrastText: '#ffffff',
                main: '#01ab56',
            },
            success: {
                contrastText: '#ffffff',
                main: '#4caf50',
            },
            text: {
                primary: '#ffffff',
                secondary: '#919eab',
            },
            warning: {
                contrastText: '#ffffff',
                main: '#ff9800',
            },
        },
        shadows: darkShadows,
    },
}

export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
    let themeOptions = themesOptions[config.theme]

    if (!themeOptions) {
        console.warn(new Error(`The theme ${config.theme} is not valid`))
        themeOptions = themesOptions[THEMES.LIGHT]
    }

    let theme = createTheme(
        merge(
            {},
            baseOptions,
            themeOptions,

            {
                direction: config.direction,
            }
        )
    )

    if (config.responsiveFontSizes) {
        theme = responsiveFontSizes(theme)
    }

    return theme
}
