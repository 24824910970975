import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useEffect, useReducer } from 'react'
import { authApi } from '../services/authApi'

import type { IUser } from 'src/types/local/user'

interface State {
    isInitialized: boolean
    isAuthenticated: boolean
    user: IUser | null
    message?: string | undefined | null
}

interface AuthContextValue extends State {
    platform: 'JWT'
    login: (username: string, password: string, remember: boolean) => Promise<void>
    logout: () => Promise<void>
    register: (username: string, password: string, city: string, country: string) => Promise<void>
    refetchMe: () => Promise<void>
    verifyCode: () => Promise<boolean>
}

interface AuthProviderProps {
    children: ReactNode
}

type InitializeAction = {
    type: 'INITIALIZE'
    payload: {
        isAuthenticated: boolean
        user: IUser | null
    }
}

type LoginAction = {
    type: 'LOGIN'
    payload: {
        user: IUser
    }
}

type LogoutAction = {
    type: 'LOGOUT'
}

type RegisterAction = {
    type: 'REGISTER'
    payload: {
        message: string | undefined
    }
}

type Action = InitializeAction | LoginAction | LogoutAction | RegisterAction

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
}

const handlers: Record<string, (state: State, action: Action) => State> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user } = action.payload
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        }
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { user } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user,
        }
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null,
    }),
    REGISTER: (state: State, action: RegisterAction): State => ({
        ...state,
        isAuthenticated: false,
        user: null,
        message: action.payload.message,
    }),
}

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    platform: 'JWT',
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    refetchMe: () => Promise.resolve(),
    verifyCode: () => Promise.resolve(true),
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            try {
                let accessToken = localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
                if (accessToken) {
                    const user = await authApi.me()
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INITIALIZE',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        }
        initialize()
    }, [])

    const login = async (username: string, password: string, remember: boolean): Promise<void> => {
        const accessToken = await authApi.login({ username, password })
        // if (remember) {
        localStorage.setItem('accessToken', accessToken)
        // } else {
        //     sessionStorage.setItem('accessToken', accessToken)
        // }
        const user = await authApi.me()

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const logout = async (): Promise<void> => {
        if (localStorage.getItem('accessToken')) {
            localStorage.removeItem('accessToken')
        } else {
            sessionStorage.removeItem('accessToken')
        }
        dispatch({ type: 'LOGOUT' })
    }

    const register = async (username: string, password: string, city: string, country: string): Promise<void> => {
        const userRegistered = await authApi.register({
            city: city,
            country: country,
            password: password,
            username: username,
        })

        if (userRegistered)
            dispatch({
                type: 'REGISTER',
                payload: {
                    message: String((userRegistered as string) || ''),
                },
            })
    }

    const refetchMe = async () => {
        try {
            let accessToken = window.sessionStorage.getItem('accessToken')
            if (!accessToken) {
                accessToken = window.localStorage.getItem('accessToken')
            }
            if (accessToken) {
                const user = await authApi.me()
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: true,
                        user,
                    },
                })
            } else {
                dispatch({
                    type: 'INITIALIZE',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        } catch (err) {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
    }

    const verifyCode = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search)
            const verifyCodeToken = urlParams.get('token')
            if (verifyCodeToken) {
                const accessToken = await authApi.verifyCode(verifyCodeToken)
                console.log({ accessToken })

                if (accessToken) {
                    localStorage.setItem('accessToken', accessToken)
                    const user = await authApi.me()
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            user,
                        },
                    })
                }
            }
            return true
        } catch (err) {
            dispatch({
                type: 'INITIALIZE',
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
            return false
        }
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                platform: 'JWT',
                login,
                logout,
                register,
                refetchMe,
                verifyCode,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AuthContext
