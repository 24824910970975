import createSvgIcon from '@mui/material/utils/createSvgIcon'

export const SongkickIcon = createSvgIcon(
    <>
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 2832 2832"
            enableBackground="new 0 0 2832 2832"
        >
            <path
                fill="#FF04A4"
                opacity="1.000000"
                stroke="none"
                d="
M561.731384,737.353760
	C567.815186,729.227600 573.329285,720.589172 580.088684,713.070374
	C601.172302,689.618042 621.982361,665.844604 644.264893,643.562012
	C682.618958,605.207764 723.572388,569.699158 767.242188,537.433899
	C820.078857,498.395844 875.778809,463.957397 934.729004,434.962677
	C1001.422913,402.159180 1070.634888,376.012756 1142.708252,357.484192
	C1191.976074,344.818542 1241.700195,334.772186 1292.332275,329.701996
	C1317.402710,327.191498 1342.537476,324.885223 1367.699341,323.837280
	C1397.933716,322.578125 1428.270020,321.554474 1458.485718,322.612244
	C1497.897705,323.991974 1537.189819,327.576050 1576.314209,333.277527
	C1629.341187,341.004944 1681.476440,352.410828 1732.794800,367.667877
	C1781.606934,382.179871 1829.117065,400.132385 1875.408569,421.416290
	C1916.258301,440.198059 1955.987183,461.056976 1993.985718,485.031311
	C2014.205078,497.788208 2033.674683,511.783813 2053.041992,525.828125
	C2074.514160,541.398743 2095.791992,557.274841 2116.562500,573.763611
	C2129.989990,584.423340 2142.530518,596.231628 2155.154053,607.866028
	C2172.475342,623.829712 2189.498779,640.116272 2206.010498,656.970520
	C2192.510254,657.602661 2179.743408,661.594666 2166.753174,658.186584
	C2163.721436,657.391174 2160.183838,658.680664 2156.873047,658.757385
	C2151.626465,658.878845 2146.355225,659.075745 2141.129395,658.723816
	C2130.621338,658.016174 2120.427246,655.118225 2109.524414,657.523926
	C2100.592285,659.494690 2091.046387,659.757446 2081.861816,659.229370
	C2071.161621,658.614075 2060.126953,660.654053 2049.731445,656.171692
	C2048.370850,655.585022 2046.457520,656.291870 2044.799194,656.382568
	C2028.902954,657.252258 2013.013672,658.623169 1997.108154,658.851013
	C1979.247192,659.106934 1961.372070,658.271667 1943.501343,658.049377
	C1939.868652,658.004211 1936.231812,658.758545 1932.590820,658.829529
	C1917.777344,659.118652 1902.961548,659.299011 1888.146118,659.466064
	C1887.177490,659.476929 1886.214600,658.916565 1885.230713,658.830078
	C1874.739868,657.908569 1864.567505,655.884705 1853.634033,657.308960
	C1842.714355,658.731323 1831.593018,660.428650 1820.017212,658.899902
	C1812.122070,657.857239 1803.763062,659.366882 1795.844116,656.278198
	C1794.394531,655.712708 1792.537720,656.006775 1790.892212,656.157898
	C1776.370239,657.491272 1761.853760,658.881897 1747.335571,660.255615
	C1738.257446,655.551575 1729.103394,650.987427 1720.113647,646.120178
	C1670.052368,619.016113 1617.215454,599.132507 1561.834229,586.253845
	C1538.161987,580.749023 1514.216797,576.134399 1490.179565,572.574646
	C1471.262695,569.773315 1452.024292,569.069763 1432.909302,567.709106
	C1422.328247,566.955994 1411.705566,566.352783 1401.104248,566.391541
	C1333.225586,566.640198 1266.937622,576.706909 1202.624390,598.888062
	C1140.940796,620.162231 1085.262329,651.916260 1035.992310,694.672424
	C1020.974915,707.704407 1006.807312,721.718079 992.329285,735.365723
	C990.694092,736.907166 989.573059,738.994141 986.974243,741.401001
	C971.541321,744.032349 957.356934,745.400513 943.073853,742.524231
	C941.272339,742.161499 938.940491,742.656433 937.306580,743.566223
	C932.125977,746.450500 927.106995,745.139038 921.809143,744.078125
	C917.006592,743.116455 912.007690,742.819031 907.094910,742.815247
	C902.471802,742.811646 897.850952,743.902039 893.224243,743.941284
	C875.978333,744.087463 858.730103,744.065674 841.483765,743.949036
	C837.516418,743.922241 833.560120,743.131470 829.586731,742.933167
	C822.957703,742.602417 816.313599,742.185974 809.685547,742.312866
	C799.475647,742.508484 789.273376,743.492249 779.068848,743.476990
	C768.219360,743.460754 757.374268,742.536438 746.520874,742.264221
	C739.250488,742.081787 731.968872,742.342285 724.692322,742.409363
	C712.790466,742.519043 700.887329,742.564697 688.987671,742.793030
	C686.073486,742.848938 682.925720,742.925049 680.318176,744.021362
	C676.012512,745.831726 672.055603,745.368164 667.682373,744.507507
	C662.223816,743.433228 656.588806,742.953430 651.017822,742.846008
	C646.403442,742.756958 641.774231,743.902405 637.146790,743.942200
	C620.234009,744.087769 603.316284,744.182617 586.406860,743.904480
	C580.130310,743.801208 573.808411,742.943420 567.638855,741.749390
	C565.467468,741.329163 563.689148,738.877747 561.731384,737.353760
z"
            />
            <path
                fill="#FE264A"
                opacity="1.000000"
                stroke="none"
                d="
M2152.627441,2232.278564
	C2141.047363,2242.670654 2129.830566,2253.504395 2117.825928,2263.380371
	C2058.008789,2312.591553 1993.963501,2355.445801 1925.193726,2391.237793
	C1851.108765,2429.796143 1773.746826,2459.635498 1692.835205,2480.385254
	C1641.906860,2493.446045 1590.332520,2502.538330 1538.092285,2508.515137
	C1482.724609,2514.849609 1427.247437,2515.536621 1371.708374,2513.581055
	C1276.877808,2510.242188 1184.202271,2493.797607 1093.696533,2465.437500
	C958.752991,2423.152344 836.432434,2357.305420 726.902222,2267.789795
	C701.131775,2246.728271 676.031433,2224.920654 653.078491,2200.259766
	C655.406616,2199.217041 657.758179,2198.282227 659.223938,2199.046387
	C666.068237,2202.615234 672.347168,2201.596436 679.316772,2198.912598
	C687.756165,2195.662109 696.582581,2194.438965 705.597717,2198.403076
	C708.222229,2199.556885 711.966125,2198.145508 715.198853,2197.939453
	C722.772095,2197.456543 730.349426,2196.563232 737.919739,2196.614990
	C750.437317,2196.700439 762.949158,2197.449951 775.466492,2197.815674
	C780.385498,2197.959473 785.314636,2197.909668 790.235718,2197.796875
	C798.799316,2197.601074 807.359497,2197.255127 815.922607,2197.031006
	C826.786438,2196.746826 837.651672,2196.512695 848.516968,2196.289062
	C849.164978,2196.275635 849.883789,2196.413330 850.460144,2196.700684
	C858.700867,2200.806396 867.320251,2198.949463 875.787842,2198.107910
	C884.423218,2197.249756 892.899963,2192.913574 901.701172,2197.798584
	C903.241028,2198.653564 905.660156,2197.774902 907.625977,2198.042725
	C911.867554,2198.620850 916.239136,2198.939697 920.256653,2200.271973
	C924.304199,2201.614258 927.806580,2201.536865 931.845276,2200.211182
	C937.487610,2198.359131 944.097534,2195.041260 949.091431,2196.490723
	C961.836670,2200.190918 973.908569,2196.465820 986.251343,2196.021484
	C987.911804,2195.961670 989.580933,2196.081543 991.243958,2196.160889
	C1003.751831,2196.757324 1016.262207,2197.902100 1028.766113,2197.833252
	C1045.221680,2197.742920 1061.670410,2196.650146 1078.126221,2196.114990
	C1081.093872,2196.018799 1084.162476,2196.180176 1087.043335,2196.838867
	C1093.936523,2198.416016 1100.156616,2196.799561 1106.173706,2193.604492
	C1113.770264,2197.229980 1121.579346,2200.471436 1128.930420,2204.540771
	C1199.689941,2243.710938 1274.973633,2269.650391 1354.832031,2282.435547
	C1380.736694,2286.583008 1406.692871,2289.915527 1432.969482,2290.372559
	C1447.574341,2290.626465 1462.190063,2291.908447 1476.771851,2291.549561
	C1537.109253,2290.064453 1596.390503,2281.499512 1654.232056,2263.830811
	C1687.593262,2253.639893 1719.820312,2240.716309 1751.490479,2224.895020
	C1756.934204,2233.806152 1764.613770,2231.877686 1771.828491,2231.510254
	C1776.418213,2231.276367 1781.013062,2231.140381 1785.601929,2230.891846
	C1790.815796,2230.609619 1796.187378,2230.931641 1801.192993,2229.740723
	C1806.576050,2228.460449 1811.400024,2227.915771 1816.720947,2230.107422
	C1819.562866,2231.278076 1823.142944,2231.021729 1826.359863,2230.849365
	C1835.914673,2230.337646 1845.310059,2228.775635 1855.056763,2229.647949
	C1868.603760,2230.860352 1882.290649,2230.498535 1895.916992,2230.842529
	C1904.838745,2231.067871 1913.760498,2231.557861 1922.679565,2231.501221
	C1926.172241,2231.479248 1930.296265,2231.232910 1932.997681,2229.408203
	C1937.135742,2226.613281 1940.639893,2226.898193 1945.135620,2227.891357
	C1956.426392,2230.385986 1967.749146,2231.436768 1979.543457,2230.634277
	C1991.828491,2229.797607 2003.866577,2226.978760 2016.951904,2230.866943
	C2028.102417,2234.179932 2041.240723,2232.369629 2053.220947,2230.756836
	C2060.865234,2229.727783 2067.961182,2227.064941 2075.365234,2231.167969
	C2076.419189,2231.751953 2078.049316,2231.794678 2079.271729,2231.500977
	C2094.710449,2227.793213 2110.332275,2229.808594 2125.867676,2230.106934
	C2134.798096,2230.278076 2143.708252,2231.519043 2152.627441,2232.278564
z"
            />
            <path
                fill="#FF137C"
                opacity="1.000000"
                stroke="none"
                d="
M2499.889893,1233.997437
	C2502.483887,1250.353516 2505.837891,1266.632568 2507.502930,1283.082642
	C2510.341064,1311.120239 2512.847412,1339.231689 2514.138428,1367.374268
	C2515.463135,1396.255859 2515.164307,1425.211914 2514.511230,1455.058228
	C2499.122070,1455.986450 2484.796143,1456.096313 2470.473145,1455.928589
	C2466.525879,1455.882324 2462.306641,1455.739380 2458.706543,1454.354980
	C2451.939209,1451.752563 2445.879639,1452.806763 2439.371582,1455.202759
	C2435.176758,1456.747070 2429.878418,1458.358276 2426.023682,1457.079346
	C2419.391357,1454.879272 2413.287598,1454.919067 2406.560303,1455.683350
	C2400.380127,1456.385498 2393.989746,1455.272827 2387.694824,1454.907593
	C2385.712891,1454.792603 2383.731201,1454.265747 2381.765137,1454.340332
	C2368.822266,1454.830444 2355.881348,1455.940308 2342.944824,1455.869385
	C2327.431152,1455.784302 2311.926758,1454.436035 2296.409912,1454.212036
	C2289.148926,1454.107178 2281.874023,1455.815674 2274.595703,1455.906006
	C2254.277344,1456.158203 2233.953613,1456.064087 2213.633301,1455.930908
	C2210.019043,1455.907227 2206.123535,1455.743286 2202.857422,1454.415771
	C2195.743164,1451.524414 2189.352051,1452.806396 2182.603027,1455.556763
	C2179.053955,1457.002930 2174.246338,1458.615356 2171.174316,1457.340332
	C2163.280518,1454.063721 2155.607422,1455.899780 2147.750488,1455.803711
	C2134.523193,1455.642090 2121.323975,1455.582520 2108.088379,1455.743530
	C2085.260010,1456.021484 2062.413330,1454.656860 2039.571289,1454.105957
	C2035.937744,1454.018433 2032.285156,1454.541992 2028.647339,1454.853271
	C2024.680420,1455.192383 2020.722290,1455.922363 2016.757080,1455.946045
	C1997.435913,1456.061523 1978.112793,1456.096680 1958.792480,1455.921021
	C1954.522827,1455.882202 1949.974243,1455.628784 1946.061768,1454.140137
	C1939.560181,1451.666138 1933.815552,1452.850464 1927.679321,1455.116211
	C1923.740479,1456.570557 1918.701050,1458.624756 1915.335205,1457.333496
	C1907.411011,1454.293213 1899.809326,1455.177612 1891.899902,1455.893066
	C1887.621094,1456.280029 1883.216064,1456.091553 1878.942017,1455.563965
	C1864.332642,1453.760864 1849.861206,1456.901367 1835.299194,1456.580200
	C1834.158691,1456.554932 1832.990845,1457.768188 1831.835693,1458.405762
	C1820.080811,1450.458618 1808.519897,1442.204956 1796.535278,1434.620605
	C1756.977417,1409.586914 1715.305542,1388.733521 1671.653931,1371.766113
	C1619.872559,1351.639038 1566.675415,1336.086182 1513.094360,1321.664429
	C1456.673950,1306.478516 1399.874023,1292.593262 1344.514526,1273.689331
	C1315.867798,1263.907227 1287.891602,1252.493408 1260.936035,1237.166016
	C1265.382935,1234.655151 1270.155029,1233.435913 1274.688477,1231.621094
	C1279.759033,1229.591431 1284.552856,1229.658203 1288.962402,1232.747314
	C1294.108276,1236.352173 1299.421021,1236.458374 1305.141602,1234.694946
	C1311.893066,1232.613647 1318.658813,1231.451416 1325.734497,1233.639648
	C1328.189209,1234.398682 1331.037964,1234.104370 1333.689819,1233.965820
	C1344.969360,1233.377197 1356.262207,1232.896606 1367.513550,1231.944824
	C1383.418213,1230.599487 1399.278198,1236.236084 1415.176270,1232.093750
	C1415.793457,1231.932983 1416.553345,1231.968262 1417.160034,1232.171265
	C1432.365845,1237.260498 1448.342773,1229.365601 1463.572388,1234.907593
	C1465.235718,1235.512939 1467.480591,1234.449097 1469.465210,1234.255493
	C1476.061768,1233.611328 1482.754761,1232.032104 1489.237793,1232.644531
	C1497.104736,1233.387695 1504.743042,1236.394409 1512.634399,1232.590454
	C1513.918945,1231.971191 1515.969116,1232.455444 1517.506958,1232.936401
	C1522.266479,1234.425049 1526.676392,1233.690552 1531.061279,1231.578491
	C1535.622803,1229.381226 1540.355957,1229.536133 1544.389648,1232.451904
	C1550.193848,1236.647583 1556.056030,1236.055908 1562.486328,1234.472534
	C1567.230469,1233.304443 1572.257568,1232.957275 1577.167847,1232.886963
	C1581.789551,1232.820557 1586.440063,1234.065674 1591.048340,1233.901978
	C1600.668945,1233.560425 1610.286255,1232.847900 1619.875488,1231.966675
	C1637.107666,1230.383423 1654.305908,1236.167236 1671.535645,1232.102905
	C1672.158325,1231.956055 1672.908569,1232.003418 1673.519531,1232.203613
	C1688.734253,1237.188721 1704.692505,1229.479614 1719.932007,1234.901978
	C1721.599854,1235.495361 1723.836304,1234.371948 1725.822510,1234.211670
	C1732.743652,1233.653198 1739.740845,1232.320923 1746.576294,1232.886719
	C1753.786499,1233.484009 1760.766724,1236.302368 1768.014648,1232.702759
	C1769.545532,1231.942505 1771.911255,1233.044922 1773.870728,1232.927979
	C1778.074585,1232.677246 1782.490601,1232.891235 1786.403687,1231.627197
	C1791.536255,1229.969116 1796.300415,1229.496704 1800.714478,1232.615601
	C1806.193726,1236.486938 1811.738525,1236.067627 1817.864014,1234.626221
	C1822.937866,1233.432129 1828.287231,1232.980591 1833.516235,1232.968384
	C1842.811890,1232.946777 1852.241699,1234.639648 1861.376099,1233.570557
	C1877.035767,1231.738159 1892.485352,1231.203491 1908.091431,1233.652100
	C1911.925171,1234.253784 1916.011841,1233.300781 1919.976685,1232.992188
	C1922.952271,1232.760620 1926.223145,1231.454346 1928.839722,1232.281250
	C1944.134521,1237.114746 1960.073120,1229.358521 1975.286133,1234.917725
	C1976.677979,1235.426270 1978.551758,1234.639526 1980.197998,1234.424927
	C1991.059937,1233.009888 2001.713989,1232.395630 2012.827271,1234.170044
	C2020.896484,1235.458374 2029.571899,1232.226440 2038.134155,1233.316772
	C2039.592896,1233.502441 2041.274780,1232.336670 2042.784424,1231.644653
	C2047.765137,1229.361084 2052.632324,1229.698853 2057.033447,1232.787231
	C2062.187012,1236.403687 2067.511719,1236.434814 2073.219482,1234.671631
	C2079.975586,1232.584839 2086.745117,1231.482788 2093.814697,1233.652100
	C2096.271729,1234.405884 2099.118896,1234.105347 2101.771240,1233.966553
	C2113.050537,1233.376465 2124.343750,1232.898804 2135.594727,1231.944458
	C2151.499756,1230.595337 2167.359375,1236.271484 2183.257812,1232.096069
	C2183.873779,1231.934448 2184.633545,1231.975952 2185.241211,1232.178467
	C2200.448975,1237.241455 2216.421631,1229.394165 2231.653564,1234.906494
	C2233.318115,1235.508789 2235.564697,1234.476318 2237.545410,1234.237061
	C2247.750488,1233.003540 2257.735107,1232.242920 2268.204102,1234.060669
	C2276.616211,1235.521240 2285.599121,1232.106323 2294.479248,1233.213379
	C2295.935303,1233.394897 2297.600586,1232.188110 2299.120850,1231.513794
	C2303.757080,1229.457642 2308.436523,1229.575317 2312.462402,1232.488525
	C2318.275391,1236.694946 2324.214844,1236.320190 2330.541504,1234.349976
	C2336.656494,1232.445801 2342.711670,1232.659424 2349.183594,1233.343506
	C2358.685303,1234.347656 2368.413086,1233.189819 2378.045166,1232.995117
	C2389.676514,1232.760254 2401.312988,1232.267212 2412.939697,1232.431519
	C2429.442383,1232.664551 2445.937012,1233.451172 2462.438721,1233.848267
	C2464.718018,1233.903076 2467.004150,1232.932617 2469.313232,1232.708008
	C2471.607422,1232.485107 2474.175049,1231.948120 2476.205811,1232.689941
	C2484.030273,1235.547974 2491.960449,1234.336182 2499.889893,1233.997437
z"
            />
            <path
                fill="#FF0F87"
                opacity="1.000000"
                stroke="none"
                d="
M2499.972168,1233.171631
	C2491.960449,1234.336182 2484.030273,1235.547974 2476.205811,1232.689941
	C2474.175049,1231.948120 2471.607422,1232.485107 2469.313232,1232.708008
	C2467.004150,1232.932617 2464.718018,1233.903076 2462.438721,1233.848267
	C2445.937012,1233.451172 2429.442383,1232.664551 2412.939697,1232.431519
	C2401.312988,1232.267212 2389.676514,1232.760254 2378.045166,1232.995117
	C2368.413086,1233.189819 2358.685303,1234.347656 2349.183594,1233.343506
	C2342.711670,1232.659424 2336.656494,1232.445801 2330.541504,1234.349976
	C2324.214844,1236.320190 2318.275391,1236.694946 2312.462402,1232.488525
	C2308.436523,1229.575317 2303.757080,1229.457642 2299.120850,1231.513794
	C2297.600586,1232.188110 2295.935303,1233.394897 2294.479248,1233.213379
	C2285.599121,1232.106323 2276.616211,1235.521240 2268.204102,1234.060669
	C2257.735107,1232.242920 2247.750488,1233.003540 2237.545410,1234.237061
	C2235.564697,1234.476318 2233.318115,1235.508789 2231.653564,1234.906494
	C2216.421631,1229.394165 2200.448975,1237.241455 2185.241211,1232.178467
	C2184.633545,1231.975952 2183.873779,1231.934448 2183.257812,1232.096069
	C2167.359375,1236.271484 2151.499756,1230.595337 2135.594727,1231.944458
	C2124.343750,1232.898804 2113.050537,1233.376465 2101.771240,1233.966553
	C2099.118896,1234.105347 2096.271729,1234.405884 2093.814697,1233.652100
	C2086.745117,1231.482788 2079.975586,1232.584839 2073.219482,1234.671631
	C2067.511719,1236.434814 2062.187012,1236.403687 2057.033447,1232.787231
	C2052.632324,1229.698853 2047.765137,1229.361084 2042.784424,1231.644653
	C2041.274780,1232.336670 2039.592896,1233.502441 2038.134155,1233.316772
	C2029.571899,1232.226440 2020.896484,1235.458374 2012.827271,1234.170044
	C2001.713989,1232.395630 1991.059937,1233.009888 1980.197998,1234.424927
	C1978.551758,1234.639526 1976.677979,1235.426270 1975.286133,1234.917725
	C1960.073120,1229.358521 1944.134521,1237.114746 1928.839722,1232.281250
	C1926.223145,1231.454346 1922.952271,1232.760620 1919.976685,1232.992188
	C1916.011841,1233.300781 1911.925171,1234.253784 1908.091431,1233.652100
	C1892.485352,1231.203491 1877.035767,1231.738159 1861.376099,1233.570557
	C1852.241699,1234.639648 1842.811890,1232.946777 1833.516235,1232.968384
	C1828.287231,1232.980591 1822.937866,1233.432129 1817.864014,1234.626221
	C1811.738525,1236.067627 1806.193726,1236.486938 1800.714478,1232.615601
	C1796.300415,1229.496704 1791.536255,1229.969116 1786.403687,1231.627197
	C1782.490601,1232.891235 1778.074585,1232.677246 1773.870728,1232.927979
	C1771.911255,1233.044922 1769.545532,1231.942505 1768.014648,1232.702759
	C1760.766724,1236.302368 1753.786499,1233.484009 1746.576294,1232.886719
	C1739.740845,1232.320923 1732.743652,1233.653198 1725.822510,1234.211670
	C1723.836304,1234.371948 1721.599854,1235.495361 1719.932007,1234.901978
	C1704.692505,1229.479614 1688.734253,1237.188721 1673.519531,1232.203613
	C1672.908569,1232.003418 1672.158325,1231.956055 1671.535645,1232.102905
	C1654.305908,1236.167236 1637.107666,1230.383423 1619.875488,1231.966675
	C1610.286255,1232.847900 1600.668945,1233.560425 1591.048340,1233.901978
	C1586.440063,1234.065674 1581.789551,1232.820557 1577.167847,1232.886963
	C1572.257568,1232.957275 1567.230469,1233.304443 1562.486328,1234.472534
	C1556.056030,1236.055908 1550.193848,1236.647583 1544.389648,1232.451904
	C1540.355957,1229.536133 1535.622803,1229.381226 1531.061279,1231.578491
	C1526.676392,1233.690552 1522.266479,1234.425049 1517.506958,1232.936401
	C1515.969116,1232.455444 1513.918945,1231.971191 1512.634399,1232.590454
	C1504.743042,1236.394409 1497.104736,1233.387695 1489.237793,1232.644531
	C1482.754761,1232.032104 1476.061768,1233.611328 1469.465210,1234.255493
	C1467.480591,1234.449097 1465.235718,1235.512939 1463.572388,1234.907593
	C1448.342773,1229.365601 1432.365845,1237.260498 1417.160034,1232.171265
	C1416.553345,1231.968262 1415.793457,1231.932983 1415.176270,1232.093750
	C1399.278198,1236.236084 1383.418213,1230.599487 1367.513550,1231.944824
	C1356.262207,1232.896606 1344.969360,1233.377197 1333.689819,1233.965820
	C1331.037964,1234.104370 1328.189209,1234.398682 1325.734497,1233.639648
	C1318.658813,1231.451416 1311.893066,1232.613647 1305.141602,1234.694946
	C1299.421021,1236.458374 1294.108276,1236.352173 1288.962402,1232.747314
	C1284.552856,1229.658203 1279.759033,1229.591431 1274.688477,1231.621094
	C1270.155029,1233.435913 1265.382935,1234.655151 1260.359375,1236.735107
	C1213.260132,1213.277710 1171.723145,1182.999634 1142.662720,1138.107056
	C1126.168091,1112.626221 1118.082275,1084.454956 1118.502686,1054.141235
	C1118.644165,1043.941650 1120.828979,1033.770386 1123.099976,1022.723145
	C1131.790161,1018.459167 1139.328735,1017.742432 1146.918579,1022.324585
	C1150.297852,1024.364624 1154.061768,1024.310303 1157.238525,1021.721252
	C1161.749756,1018.044800 1166.487915,1019.378784 1171.484375,1020.210876
	C1175.958496,1020.955811 1180.619995,1021.157898 1185.150391,1020.860718
	C1194.289429,1020.261047 1203.380127,1018.889465 1212.521118,1018.365662
	C1218.074585,1018.047424 1223.711182,1019.163513 1229.263184,1018.835999
	C1236.618774,1018.402161 1243.759644,1018.204285 1250.806274,1021.039917
	C1253.134277,1021.976746 1256.504395,1022.482056 1258.577148,1021.482422
	C1264.378662,1018.684570 1270.173584,1019.741516 1276.100708,1019.875122
	C1280.038086,1019.963928 1284.015625,1019.127563 1287.944702,1018.511047
	C1293.364624,1017.660645 1298.680176,1017.660217 1303.323486,1020.950928
	C1308.244507,1024.438599 1312.996948,1024.022461 1318.420532,1022.018005
	C1321.994629,1020.697083 1326.398071,1019.931213 1330.009277,1020.756958
	C1337.139526,1022.387268 1343.891113,1021.940369 1350.670410,1019.829895
	C1356.559814,1017.996460 1362.305298,1017.908386 1368.354126,1019.432251
	C1372.773926,1020.545715 1378.042358,1021.971252 1381.972168,1020.567993
	C1389.688965,1017.812561 1396.143921,1019.465820 1403.156616,1022.267883
	C1405.858887,1023.347656 1410.216431,1023.440002 1412.439697,1021.938599
	C1417.890869,1018.257507 1423.023315,1019.458374 1428.665405,1020.477966
	C1432.781738,1021.221802 1437.135132,1021.094055 1441.343628,1020.797791
	C1450.483398,1020.154480 1459.582886,1018.847412 1468.727783,1018.395874
	C1476.271851,1018.023376 1483.857666,1018.476501 1491.424561,1018.594238
	C1494.057861,1018.635193 1496.874023,1018.192200 1499.288086,1018.965210
	C1506.702637,1021.339417 1513.905884,1023.658020 1521.459229,1019.287109
	C1522.632324,1018.608337 1524.692993,1019.623657 1526.331909,1019.560913
	C1531.950195,1019.346252 1537.608765,1019.344238 1543.166016,1018.610107
	C1548.950928,1017.845886 1554.546387,1017.542542 1559.505615,1021.056824
	C1564.469727,1024.574585 1569.228149,1023.890747 1574.614380,1021.939026
	C1578.193481,1020.642090 1582.575195,1019.993042 1586.220947,1020.786011
	C1592.995850,1022.259583 1599.442017,1022.098999 1605.898560,1019.994263
	C1612.445923,1017.859924 1618.848999,1018.042969 1625.546021,1019.634216
	C1629.936401,1020.677429 1635.077393,1021.562744 1639.152588,1020.251282
	C1645.801025,1018.112061 1651.545532,1018.952515 1657.437500,1021.752625
	C1661.191895,1023.536926 1665.091797,1024.908325 1668.738159,1022.014648
	C1673.559326,1018.188965 1678.541016,1019.228027 1683.898804,1020.305054
	C1688.026001,1021.134583 1692.400269,1021.360657 1696.605103,1021.050049
	C1705.737061,1020.375427 1714.811646,1018.839111 1723.947266,1018.327332
	C1730.160522,1017.979370 1736.490601,1019.403137 1742.671875,1018.884216
	C1749.436157,1018.316345 1755.762329,1018.674927 1762.261841,1020.821228
	C1765.504028,1021.891907 1769.399780,1021.155029 1772.985840,1020.965881
	C1776.840576,1020.762573 1780.701904,1019.772766 1784.519165,1019.961182
	C1792.079712,1020.334351 1799.222168,1020.002930 1804.637695,1013.730530
	C1819.684570,1024.048706 1834.770752,1034.310181 1849.771240,1044.695312
	C1904.746582,1082.755859 1960.396362,1119.674805 2019.997925,1150.333740
	C2071.357910,1176.753174 2124.488525,1198.010742 2181.406982,1208.967896
	C2213.494385,1215.144775 2245.821533,1217.545288 2278.513428,1215.831787
	C2333.330566,1212.958740 2385.177246,1198.824707 2434.615479,1175.435913
	C2448.120117,1169.046997 2461.235596,1161.833862 2474.513428,1154.967285
	C2481.782715,1151.207886 2482.993652,1151.334473 2484.749268,1159.300415
	C2490.102539,1183.593506 2494.987061,1207.989868 2499.972168,1233.171631
z"
            />
            <path
                fill="#FF137C"
                opacity="1.000000"
                stroke="none"
                d="
M859.515808,1514.191040
	C848.039124,1504.652710 836.679199,1494.969727 825.066223,1485.600220
	C762.787292,1435.353394 692.003723,1403.581787 613.737610,1387.598389
	C569.813416,1378.628296 525.549133,1375.563721 480.971863,1378.523560
	C444.801880,1380.924927 409.242218,1387.666504 374.618896,1398.641846
	C361.015167,1402.954102 347.663513,1408.059204 334.173615,1412.735474
	C331.452667,1413.678711 328.625519,1414.315674 324.452820,1415.484375
	C324.140747,1410.870605 323.753937,1407.414429 323.696442,1403.952881
	C323.089020,1367.373657 325.897125,1330.975464 329.986115,1294.676147
	C335.264618,1247.817139 343.358795,1201.440918 354.938507,1155.701660
	C355.509094,1153.447876 356.018127,1151.178223 357.854309,1148.695801
	C363.744751,1148.973145 368.565857,1150.604614 372.881256,1149.732788
	C381.934692,1147.904053 390.742249,1149.471680 399.584320,1150.189453
	C405.962524,1150.707275 412.119873,1150.940674 418.279785,1148.998657
	C419.823517,1148.512085 421.572327,1148.348022 423.190887,1148.479492
	C434.354279,1149.385620 445.498932,1150.539429 456.650909,1148.223022
	C458.217621,1147.897461 460.142426,1147.919434 461.573578,1148.538574
	C471.811951,1152.967896 481.497711,1150.563477 491.407928,1147.088867
	C494.564575,1145.982178 498.540741,1146.840820 502.075043,1147.307373
	C504.657135,1147.648193 507.116791,1149.462280 509.653107,1149.512939
	C523.126770,1149.782593 536.619873,1150.074951 550.081726,1149.626221
	C560.920410,1149.264771 571.719727,1147.801147 582.547852,1146.974487
	C584.450256,1146.829224 586.409607,1147.405640 588.340759,1147.663208
	C593.855286,1148.399048 599.372131,1149.779541 604.882263,1149.751465
	C616.721802,1149.691040 628.624634,1147.904907 640.376770,1148.690674
	C652.937744,1149.530396 665.155334,1150.327515 677.887512,1148.900879
	C689.768799,1147.569702 702.128479,1150.422119 714.273315,1148.173584
	C715.517578,1147.943115 717.027893,1148.148315 718.194397,1148.656982
	C728.093567,1152.973511 737.493774,1150.571899 747.049316,1147.183228
	C749.976685,1146.145142 753.772095,1146.037964 756.748718,1146.922485
	C768.711914,1150.477173 780.763184,1149.967651 792.910828,1148.807739
	C794.871094,1148.620605 796.861511,1148.758545 798.837830,1148.729492
	C808.992798,1148.580688 819.153015,1148.577148 829.300781,1148.219482
	C835.791687,1147.990845 841.945923,1146.204102 848.728210,1149.125977
	C853.180847,1151.044434 859.170898,1149.557983 864.459045,1149.309814
	C867.421570,1149.170776 870.356689,1148.447754 873.304199,1147.987793
	C879.785889,1172.394775 887.404358,1196.390991 897.726501,1219.510498
	C916.160095,1260.797485 940.684814,1298.074341 970.484924,1331.999756
	C1017.092407,1385.059326 1072.815308,1426.375366 1134.541992,1460.074219
	C1168.132324,1478.412598 1203.117676,1493.612427 1239.302490,1508.443726
	C1239.024902,1513.798340 1236.183838,1513.109375 1232.966675,1512.810547
	C1222.877075,1511.873047 1212.779419,1509.349365 1202.858398,1514.889526
	C1200.390015,1516.268066 1195.952759,1514.471802 1192.518677,1513.699097
	C1184.841553,1511.971802 1177.260254,1508.944336 1169.612915,1513.963867
	C1168.346069,1514.795288 1166.388916,1515.139282 1164.863037,1514.921631
	C1153.636597,1513.320312 1142.513550,1509.560059 1131.075928,1513.746460
	C1130.503540,1513.955933 1129.761353,1513.698120 1129.098022,1513.662354
	C1118.840576,1513.107666 1108.569946,1512.728760 1098.328735,1511.959717
	C1082.203979,1510.748657 1065.923218,1515.168457 1049.851562,1510.417480
	C1047.637695,1509.762939 1044.777344,1511.483276 1042.182617,1511.889160
	C1037.312378,1512.651001 1032.405762,1513.882446 1027.535156,1513.791992
	C1017.984375,1513.614624 1008.452148,1512.581787 998.903015,1512.079224
	C995.598022,1511.905396 992.266235,1512.308228 988.945374,1512.351685
	C984.373108,1512.411621 979.798462,1512.317261 975.226929,1512.401733
	C966.150269,1512.569336 956.913757,1509.014160 948.015625,1514.542114
	C945.686890,1515.988770 941.410400,1515.594116 938.522583,1514.603882
	C929.916016,1511.652710 921.666504,1509.107666 912.843689,1514.183228
	C910.946167,1515.274780 907.748413,1514.369141 905.200562,1514.016113
	C898.728149,1513.119263 891.651794,1509.840332 885.975098,1511.492798
	C877.069641,1514.085083 868.144836,1511.955444 859.515808,1514.191040
z"
            />
            <path
                fill="#FF0C8F"
                opacity="1.000000"
                stroke="none"
                d="
M873.385376,1147.213867
	C870.356689,1148.447754 867.421570,1149.170776 864.459045,1149.309814
	C859.170898,1149.557983 853.180847,1151.044434 848.728210,1149.125977
	C841.945923,1146.204102 835.791687,1147.990845 829.300781,1148.219482
	C819.153015,1148.577148 808.992798,1148.580688 798.837830,1148.729492
	C796.861511,1148.758545 794.871094,1148.620605 792.910828,1148.807739
	C780.763184,1149.967651 768.711914,1150.477173 756.748718,1146.922485
	C753.772095,1146.037964 749.976685,1146.145142 747.049316,1147.183228
	C737.493774,1150.571899 728.093567,1152.973511 718.194397,1148.656982
	C717.027893,1148.148315 715.517578,1147.943115 714.273315,1148.173584
	C702.128479,1150.422119 689.768799,1147.569702 677.887512,1148.900879
	C665.155334,1150.327515 652.937744,1149.530396 640.376770,1148.690674
	C628.624634,1147.904907 616.721802,1149.691040 604.882263,1149.751465
	C599.372131,1149.779541 593.855286,1148.399048 588.340759,1147.663208
	C586.409607,1147.405640 584.450256,1146.829224 582.547852,1146.974487
	C571.719727,1147.801147 560.920410,1149.264771 550.081726,1149.626221
	C536.619873,1150.074951 523.126770,1149.782593 509.653107,1149.512939
	C507.116791,1149.462280 504.657135,1147.648193 502.075043,1147.307373
	C498.540741,1146.840820 494.564575,1145.982178 491.407928,1147.088867
	C481.497711,1150.563477 471.811951,1152.967896 461.573578,1148.538574
	C460.142426,1147.919434 458.217621,1147.897461 456.650909,1148.223022
	C445.498932,1150.539429 434.354279,1149.385620 423.190887,1148.479492
	C421.572327,1148.348022 419.823517,1148.512085 418.279785,1148.998657
	C412.119873,1150.940674 405.962524,1150.707275 399.584320,1150.189453
	C390.742249,1149.471680 381.934692,1147.904053 372.881256,1149.732788
	C368.565857,1150.604614 363.744751,1148.973145 358.370789,1148.147705
	C361.540619,1132.733154 365.099854,1117.530518 369.513550,1102.580200
	C386.690765,1044.396851 409.057861,988.204407 435.929443,933.852173
	C464.009979,877.054749 496.753601,823.028625 534.416077,772.040039
	C542.909119,760.541870 551.802673,749.339417 561.121582,737.677307
	C563.689148,738.877747 565.467468,741.329163 567.638855,741.749390
	C573.808411,742.943420 580.130310,743.801208 586.406860,743.904480
	C603.316284,744.182617 620.234009,744.087769 637.146790,743.942200
	C641.774231,743.902405 646.403442,742.756958 651.017822,742.846008
	C656.588806,742.953430 662.223816,743.433228 667.682373,744.507507
	C672.055603,745.368164 676.012512,745.831726 680.318176,744.021362
	C682.925720,742.925049 686.073486,742.848938 688.987671,742.793030
	C700.887329,742.564697 712.790466,742.519043 724.692322,742.409363
	C731.968872,742.342285 739.250488,742.081787 746.520874,742.264221
	C757.374268,742.536438 768.219360,743.460754 779.068848,743.476990
	C789.273376,743.492249 799.475647,742.508484 809.685547,742.312866
	C816.313599,742.185974 822.957703,742.602417 829.586731,742.933167
	C833.560120,743.131470 837.516418,743.922241 841.483765,743.949036
	C858.730103,744.065674 875.978333,744.087463 893.224243,743.941284
	C897.850952,743.902039 902.471802,742.811646 907.094910,742.815247
	C912.007690,742.819031 917.006592,743.116455 921.809143,744.078125
	C927.106995,745.139038 932.125977,746.450500 937.306580,743.566223
	C938.940491,742.656433 941.272339,742.161499 943.073853,742.524231
	C957.356934,745.400513 971.541321,744.032349 986.559937,741.969360
	C971.687561,762.923889 954.894958,783.160889 940.507996,804.983887
	C904.234802,860.004944 880.635376,920.219421 869.548035,985.349365
	C863.772217,1019.278137 861.969727,1053.378906 864.606140,1087.546753
	C866.129456,1107.288330 870.421021,1126.816040 873.385376,1147.213867
z"
            />
            <path
                fill="#FF1A6B"
                opacity="1.000000"
                stroke="none"
                d="
M859.794678,1514.813232
	C868.144836,1511.955444 877.069641,1514.085083 885.975098,1511.492798
	C891.651794,1509.840332 898.728149,1513.119263 905.200562,1514.016113
	C907.748413,1514.369141 910.946167,1515.274780 912.843689,1514.183228
	C921.666504,1509.107666 929.916016,1511.652710 938.522583,1514.603882
	C941.410400,1515.594116 945.686890,1515.988770 948.015625,1514.542114
	C956.913757,1509.014160 966.150269,1512.569336 975.226929,1512.401733
	C979.798462,1512.317261 984.373108,1512.411621 988.945374,1512.351685
	C992.266235,1512.308228 995.598022,1511.905396 998.903015,1512.079224
	C1008.452148,1512.581787 1017.984375,1513.614624 1027.535156,1513.791992
	C1032.405762,1513.882446 1037.312378,1512.651001 1042.182617,1511.889160
	C1044.777344,1511.483276 1047.637695,1509.762939 1049.851562,1510.417480
	C1065.923218,1515.168457 1082.203979,1510.748657 1098.328735,1511.959717
	C1108.569946,1512.728760 1118.840576,1513.107666 1129.098022,1513.662354
	C1129.761353,1513.698120 1130.503540,1513.955933 1131.075928,1513.746460
	C1142.513550,1509.560059 1153.636597,1513.320312 1164.863037,1514.921631
	C1166.388916,1515.139282 1168.346069,1514.795288 1169.612915,1513.963867
	C1177.260254,1508.944336 1184.841553,1511.971802 1192.518677,1513.699097
	C1195.952759,1514.471802 1200.390015,1516.268066 1202.858398,1514.889526
	C1212.779419,1509.349365 1222.877075,1511.873047 1232.966675,1512.810547
	C1236.183838,1513.109375 1239.024902,1513.798340 1239.909302,1508.833374
	C1255.114868,1513.052368 1270.105103,1518.428711 1285.377197,1522.826172
	C1333.853638,1536.785034 1382.341797,1550.720825 1431.000732,1564.024536
	C1488.025879,1579.615479 1545.161743,1594.819702 1599.593018,1618.351440
	C1645.480347,1638.189453 1687.501953,1664.181396 1721.930786,1700.812622
	C1741.056274,1721.161621 1756.532715,1744.441772 1765.827759,1771.088013
	C1768.553589,1778.901978 1768.779419,1787.582764 1770.239746,1795.850708
	C1770.399658,1796.756226 1771.148071,1797.557739 1770.976807,1799.368408
	C1767.391968,1800.843994 1764.427246,1801.915283 1761.520142,1801.776611
	C1749.579102,1801.206909 1737.659424,1800.205322 1725.722900,1799.517212
	C1723.142456,1799.368530 1720.512695,1799.757690 1717.927734,1800.078125
	C1713.345337,1800.646118 1708.790527,1801.801636 1704.205200,1801.910278
	C1692.838257,1802.180054 1681.473633,1797.655518 1670.070190,1801.919922
	C1669.202515,1802.244141 1668.088257,1801.929199 1667.089966,1801.880981
	C1654.195801,1801.258301 1641.307251,1800.422241 1628.405762,1800.092529
	C1622.152222,1799.932739 1615.870972,1801.070679 1609.608154,1801.010498
	C1593.044800,1800.851440 1576.484619,1800.358154 1559.923096,1800.000000
	C1557.591309,1799.949585 1555.254883,1799.988525 1552.928223,1799.853394
	C1538.109741,1798.992920 1523.275269,1797.445068 1508.607056,1801.315063
	C1507.336792,1801.650269 1505.958862,1801.810913 1504.650513,1801.731201
	C1493.046265,1801.025269 1481.448364,1800.213745 1469.843628,1799.517700
	C1467.565430,1799.380859 1465.247681,1799.444214 1462.979004,1799.694824
	C1456.753906,1800.382935 1450.427490,1802.363892 1444.356323,1801.740112
	C1434.221191,1800.698730 1424.264160,1799.076416 1414.193970,1801.912476
	C1412.975098,1802.255737 1411.546753,1801.859619 1410.215698,1801.799683
	C1396.987183,1801.204956 1383.763184,1800.431763 1370.528076,1800.096680
	C1364.939941,1799.955078 1359.324707,1801.091064 1353.728516,1801.030273
	C1337.498779,1800.854126 1321.272339,1800.363159 1305.044678,1800.002319
	C1302.379761,1799.942993 1299.708496,1800.012817 1297.049805,1799.858887
	C1282.231201,1799.001221 1267.397583,1797.445190 1252.725830,1801.287842
	C1251.452515,1801.621338 1250.078735,1801.816528 1248.771973,1801.737793
	C1237.166992,1801.039429 1225.568359,1800.235229 1213.963379,1799.537476
	C1211.682251,1799.400146 1209.361816,1799.406128 1207.094238,1799.657959
	C1200.870117,1800.349243 1194.557617,1802.325928 1188.475586,1801.745850
	C1178.349121,1800.780029 1168.402100,1799.047852 1158.305054,1801.874756
	C1156.496338,1802.381104 1154.351318,1801.613281 1152.357910,1801.544556
	C1138.458008,1801.064819 1124.559814,1800.477539 1110.655518,1800.205933
	C1102.437622,1800.045410 1094.204346,1800.617188 1085.988647,1800.420288
	C1082.390625,1800.334229 1079.375854,1800.627563 1077.252197,1803.814209
	C1051.588501,1765.084961 1026.041138,1726.277954 1000.229248,1687.647705
	C972.869446,1646.700806 944.090576,1606.753052 911.653381,1569.660156
	C895.246094,1550.897827 877.315430,1533.467773 859.794678,1514.813232
z"
            />
            <path
                fill="#FF1870"
                opacity="1.000000"
                stroke="none"
                d="
M1832.137695,1459.005127
	C1832.990845,1457.768188 1834.158691,1456.554932 1835.299194,1456.580200
	C1849.861206,1456.901367 1864.332642,1453.760864 1878.942017,1455.563965
	C1883.216064,1456.091553 1887.621094,1456.280029 1891.899902,1455.893066
	C1899.809326,1455.177612 1907.411011,1454.293213 1915.335205,1457.333496
	C1918.701050,1458.624756 1923.740479,1456.570557 1927.679321,1455.116211
	C1933.815552,1452.850464 1939.560181,1451.666138 1946.061768,1454.140137
	C1949.974243,1455.628784 1954.522827,1455.882202 1958.792480,1455.921021
	C1978.112793,1456.096680 1997.435913,1456.061523 2016.757080,1455.946045
	C2020.722290,1455.922363 2024.680420,1455.192383 2028.647339,1454.853271
	C2032.285156,1454.541992 2035.937744,1454.018433 2039.571289,1454.105957
	C2062.413330,1454.656860 2085.260010,1456.021484 2108.088379,1455.743530
	C2121.323975,1455.582520 2134.523193,1455.642090 2147.750488,1455.803711
	C2155.607422,1455.899780 2163.280518,1454.063721 2171.174316,1457.340332
	C2174.246338,1458.615356 2179.053955,1457.002930 2182.603027,1455.556763
	C2189.352051,1452.806396 2195.743164,1451.524414 2202.857422,1454.415771
	C2206.123535,1455.743286 2210.019043,1455.907227 2213.633301,1455.930908
	C2233.953613,1456.064087 2254.277344,1456.158203 2274.595703,1455.906006
	C2281.874023,1455.815674 2289.148926,1454.107178 2296.409912,1454.212036
	C2311.926758,1454.436035 2327.431152,1455.784302 2342.944824,1455.869385
	C2355.881348,1455.940308 2368.822266,1454.830444 2381.765137,1454.340332
	C2383.731201,1454.265747 2385.712891,1454.792603 2387.694824,1454.907593
	C2393.989746,1455.272827 2400.380127,1456.385498 2406.560303,1455.683350
	C2413.287598,1454.919067 2419.391357,1454.879272 2426.023682,1457.079346
	C2429.878418,1458.358276 2435.176758,1456.747070 2439.371582,1455.202759
	C2445.879639,1452.806763 2451.939209,1451.752563 2458.706543,1454.354980
	C2462.306641,1455.739380 2466.525879,1455.882324 2470.473145,1455.928589
	C2484.796143,1456.096313 2499.122070,1455.986450 2514.334473,1455.978760
	C2514.678955,1469.513428 2514.544189,1483.082397 2513.487549,1496.578979
	C2512.224365,1512.716553 2510.136963,1528.788696 2508.469238,1544.895630
	C2503.068359,1597.050781 2492.835205,1648.297974 2478.408447,1699.714111
	C2468.325195,1700.508545 2459.427979,1700.579468 2450.531006,1700.630127
	C2447.559570,1700.647095 2444.586426,1700.558228 2441.615967,1700.610107
	C2422.143555,1700.950195 2402.672363,1701.324951 2383.199707,1701.644775
	C2382.226074,1701.660645 2381.248291,1701.117065 2380.267578,1701.098877
	C2377.319824,1701.043579 2374.369629,1701.110840 2371.420410,1701.145874
	C2358.546143,1701.298950 2345.671387,1701.561523 2332.797363,1701.541626
	C2329.520020,1701.536499 2325.803955,1699.395874 2323.044678,1700.371826
	C2311.613281,1704.414673 2300.028320,1700.971069 2288.584473,1701.968384
	C2280.658691,1702.659180 2272.698730,1703.572021 2264.768066,1703.453613
	C2252.833252,1703.275757 2240.913086,1702.218872 2228.980713,1701.668701
	C2224.412842,1701.458008 2219.827881,1701.605957 2215.250732,1701.603516
	C2199.068848,1701.595093 2182.886963,1701.622925 2166.705566,1701.540039
	C2165.080078,1701.531738 2163.473633,1700.686523 2161.830811,1700.543579
	C2158.858154,1700.285156 2155.858643,1700.073730 2152.881348,1700.174561
	C2136.722656,1700.722412 2120.570068,1701.451294 2104.409912,1701.946289
	C2098.168213,1702.137451 2091.912598,1701.861450 2085.663330,1701.827393
	C2075.567139,1701.772339 2065.644043,1700.932861 2055.342285,1702.227905
	C2041.301025,1703.993164 2026.816528,1702.233276 2012.521973,1701.984863
	C2003.810303,1680.424805 1996.247314,1658.309204 1986.143066,1637.422729
	C1966.307739,1596.420044 1939.508667,1560.089233 1908.421265,1526.766724
	C1885.217529,1501.894653 1859.828857,1479.658813 1832.137695,1459.005127
z"
            />
            <path
                fill="#FF215A"
                opacity="1.000000"
                stroke="none"
                d="
M1105.859131,2192.988770
	C1100.156616,2196.799561 1093.936523,2198.416016 1087.043335,2196.838867
	C1084.162476,2196.180176 1081.093872,2196.018799 1078.126221,2196.114990
	C1061.670410,2196.650146 1045.221680,2197.742920 1028.766113,2197.833252
	C1016.262207,2197.902100 1003.751831,2196.757324 991.243958,2196.160889
	C989.580933,2196.081543 987.911804,2195.961670 986.251343,2196.021484
	C973.908569,2196.465820 961.836670,2200.190918 949.091431,2196.490723
	C944.097534,2195.041260 937.487610,2198.359131 931.845276,2200.211182
	C927.806580,2201.536865 924.304199,2201.614258 920.256653,2200.271973
	C916.239136,2198.939697 911.867554,2198.620850 907.625977,2198.042725
	C905.660156,2197.774902 903.241028,2198.653564 901.701172,2197.798584
	C892.899963,2192.913574 884.423218,2197.249756 875.787842,2198.107910
	C867.320251,2198.949463 858.700867,2200.806396 850.460144,2196.700684
	C849.883789,2196.413330 849.164978,2196.275635 848.516968,2196.289062
	C837.651672,2196.512695 826.786438,2196.746826 815.922607,2197.031006
	C807.359497,2197.255127 798.799316,2197.601074 790.235718,2197.796875
	C785.314636,2197.909668 780.385498,2197.959473 775.466492,2197.815674
	C762.949158,2197.449951 750.437317,2196.700439 737.919739,2196.614990
	C730.349426,2196.563232 722.772095,2197.456543 715.198853,2197.939453
	C711.966125,2198.145508 708.222229,2199.556885 705.597717,2198.403076
	C696.582581,2194.438965 687.756165,2195.662109 679.316772,2198.912598
	C672.347168,2201.596436 666.068237,2202.615234 659.223938,2199.046387
	C657.758179,2198.282227 655.406616,2199.217041 652.819885,2199.648926
	C634.574341,2181.858154 616.322144,2164.369873 599.503357,2145.597412
	C563.097839,2104.962158 529.611145,2061.930664 500.593292,2015.707886
	C481.358185,1985.068481 463.932709,1953.291016 445.804810,1921.960571
	C442.317474,1915.933472 439.330872,1909.616455 436.941803,1902.713867
	C443.226440,1900.903564 448.863892,1898.367188 454.100739,1899.024170
	C464.839233,1900.371216 475.275940,1899.982788 485.835480,1898.172119
	C488.390747,1897.734131 491.127075,1898.410767 493.782654,1898.489258
	C508.047668,1898.911377 522.314819,1899.661255 536.578552,1899.606445
	C549.770691,1899.555786 562.958374,1898.602295 576.151123,1898.133179
	C577.786194,1898.074951 579.436829,1898.711670 581.086121,1898.745728
	C585.673340,1898.841064 590.266907,1898.901489 594.852051,1898.768921
	C602.427063,1898.550049 610.300537,1896.721680 617.491821,1898.228027
	C626.195251,1900.051147 634.544556,1900.058716 643.160767,1899.227051
	C652.979126,1898.279175 663.203430,1901.882935 672.673462,1896.487549
	C673.549927,1895.988159 675.230225,1896.866821 676.528442,1897.146362
	C682.056274,1898.336548 687.564392,1899.626221 693.113708,1900.704468
	C695.690979,1901.205078 699.061462,1902.470581 700.838989,1901.379761
	C707.591248,1897.235718 714.459290,1900.007690 721.248291,1900.027222
	C734.119873,1900.064331 746.841553,1898.661865 759.893799,1899.443726
	C783.958374,1900.885132 808.266541,1900.897705 832.367432,1897.867920
	C838.813293,1907.134644 845.419067,1916.294800 851.678040,1925.686157
	C882.892761,1972.523071 915.610779,2018.240479 952.614075,2060.736328
	C980.649902,2092.933350 1011.535828,2122.103760 1044.789429,2148.958740
	C1064.240723,2164.667480 1084.430054,2179.108398 1105.859131,2192.988770
z"
            />
            <path
                fill="#FE1E60"
                opacity="1.000000"
                stroke="none"
                d="
M1077.570312,1804.350342
	C1079.375854,1800.627563 1082.390625,1800.334229 1085.988647,1800.420288
	C1094.204346,1800.617188 1102.437622,1800.045410 1110.655518,1800.205933
	C1124.559814,1800.477539 1138.458008,1801.064819 1152.357910,1801.544556
	C1154.351318,1801.613281 1156.496338,1802.381104 1158.305054,1801.874756
	C1168.402100,1799.047852 1178.349121,1800.780029 1188.475586,1801.745850
	C1194.557617,1802.325928 1200.870117,1800.349243 1207.094238,1799.657959
	C1209.361816,1799.406128 1211.682251,1799.400146 1213.963379,1799.537476
	C1225.568359,1800.235229 1237.166992,1801.039429 1248.771973,1801.737793
	C1250.078735,1801.816528 1251.452515,1801.621338 1252.725830,1801.287842
	C1267.397583,1797.445190 1282.231201,1799.001221 1297.049805,1799.858887
	C1299.708496,1800.012817 1302.379761,1799.942993 1305.044678,1800.002319
	C1321.272339,1800.363159 1337.498779,1800.854126 1353.728516,1801.030273
	C1359.324707,1801.091064 1364.939941,1799.955078 1370.528076,1800.096680
	C1383.763184,1800.431763 1396.987183,1801.204956 1410.215698,1801.799683
	C1411.546753,1801.859619 1412.975098,1802.255737 1414.193970,1801.912476
	C1424.264160,1799.076416 1434.221191,1800.698730 1444.356323,1801.740112
	C1450.427490,1802.363892 1456.753906,1800.382935 1462.979004,1799.694824
	C1465.247681,1799.444214 1467.565430,1799.380859 1469.843628,1799.517700
	C1481.448364,1800.213745 1493.046265,1801.025269 1504.650513,1801.731201
	C1505.958862,1801.810913 1507.336792,1801.650269 1508.607056,1801.315063
	C1523.275269,1797.445068 1538.109741,1798.992920 1552.928223,1799.853394
	C1555.254883,1799.988525 1557.591309,1799.949585 1559.923096,1800.000000
	C1576.484619,1800.358154 1593.044800,1800.851440 1609.608154,1801.010498
	C1615.870972,1801.070679 1622.152222,1799.932739 1628.405762,1800.092529
	C1641.307251,1800.422241 1654.195801,1801.258301 1667.089966,1801.880981
	C1668.088257,1801.929199 1669.202515,1802.244141 1670.070190,1801.919922
	C1681.473633,1797.655518 1692.838257,1802.180054 1704.205200,1801.910278
	C1708.790527,1801.801636 1713.345337,1800.646118 1717.927734,1800.078125
	C1720.512695,1799.757690 1723.142456,1799.368530 1725.722900,1799.517212
	C1737.659424,1800.205322 1749.579102,1801.206909 1761.520142,1801.776611
	C1764.427246,1801.915283 1767.391968,1800.843994 1771.110596,1800.163818
	C1775.427002,1836.188843 1764.009033,1868.471680 1744.400024,1897.879517
	C1700.966675,1963.016846 1640.066040,2003.773682 1564.784790,2023.722290
	C1523.924561,2034.549805 1482.157715,2038.156006 1440.244141,2035.375488
	C1351.526245,2029.489990 1270.742676,2001.123657 1200.158691,1945.980469
	C1172.208008,1924.144287 1148.297363,1898.273071 1126.544922,1870.463135
	C1109.780884,1849.030762 1094.068481,1826.775757 1077.570312,1804.350342
z"
            />
            <path
                fill="#FF1D64"
                opacity="1.000000"
                stroke="none"
                d="
M2012.586670,1702.731689
	C2026.816528,1702.233276 2041.301025,1703.993164 2055.342285,1702.227905
	C2065.644043,1700.932861 2075.567139,1701.772339 2085.663330,1701.827393
	C2091.912598,1701.861450 2098.168213,1702.137451 2104.409912,1701.946289
	C2120.570068,1701.451294 2136.722656,1700.722412 2152.881348,1700.174561
	C2155.858643,1700.073730 2158.858154,1700.285156 2161.830811,1700.543579
	C2163.473633,1700.686523 2165.080078,1701.531738 2166.705566,1701.540039
	C2182.886963,1701.622925 2199.068848,1701.595093 2215.250732,1701.603516
	C2219.827881,1701.605957 2224.412842,1701.458008 2228.980713,1701.668701
	C2240.913086,1702.218872 2252.833252,1703.275757 2264.768066,1703.453613
	C2272.698730,1703.572021 2280.658691,1702.659180 2288.584473,1701.968384
	C2300.028320,1700.971069 2311.613281,1704.414673 2323.044678,1700.371826
	C2325.803955,1699.395874 2329.520020,1701.536499 2332.797363,1701.541626
	C2345.671387,1701.561523 2358.546143,1701.298950 2371.420410,1701.145874
	C2374.369629,1701.110840 2377.319824,1701.043579 2380.267578,1701.098877
	C2381.248291,1701.117065 2382.226074,1701.660645 2383.199707,1701.644775
	C2402.672363,1701.324951 2422.143555,1700.950195 2441.615967,1700.610107
	C2444.586426,1700.558228 2447.559570,1700.647095 2450.531006,1700.630127
	C2459.427979,1700.579468 2468.325195,1700.508545 2478.110352,1700.378052
	C2469.125000,1732.028076 2460.091553,1764.038574 2449.164795,1795.388916
	C2435.590332,1834.335205 2418.852051,1872.065308 2400.156982,1908.839844
	C2389.765869,1929.279419 2378.240479,1949.142334 2365.832031,1969.581787
	C2356.526855,1969.505005 2348.615967,1969.107544 2340.704590,1968.720703
	C2338.398438,1968.607910 2336.090088,1968.538330 2333.783691,1968.426758
	C2317.280762,1967.628540 2300.779053,1966.799561 2284.273438,1966.054688
	C2282.295654,1965.965576 2280.243164,1966.080200 2278.318604,1966.514771
	C2267.374512,1968.985962 2255.887451,1965.197876 2245.021729,1969.601074
	C2242.959229,1970.437012 2239.906250,1968.504150 2237.279785,1968.341553
	C2232.070068,1968.019043 2226.624268,1967.056274 2221.665039,1968.139282
	C2213.260498,1969.974731 2205.262695,1969.933350 2197.113037,1967.391235
	C2194.948730,1966.715942 2192.541504,1966.526978 2190.256104,1966.578857
	C2172.657227,1966.979614 2155.061768,1967.552979 2137.462646,1967.940674
	C2131.878418,1968.063721 2126.281494,1967.616577 2120.696533,1967.726196
	C2107.078369,1967.993530 2093.452393,1968.961792 2079.850586,1968.669434
	C2063.346924,1968.314575 2046.850830,1966.078247 2030.377930,1966.338623
	C2019.505249,1966.510498 2008.802124,1967.706543 1998.304321,1963.999268
	C2003.700806,1947.618408 2009.681152,1931.402832 2014.377686,1914.823608
	C2025.693970,1874.875732 2030.050537,1834.100586 2027.820435,1792.577881
	C2026.192261,1762.260132 2021.140991,1732.593750 2012.586670,1702.731689
z"
            />
            <path
                fill="#FE0997"
                opacity="1.000000"
                stroke="none"
                d="
M1747.705078,660.853271
	C1761.853760,658.881897 1776.370239,657.491272 1790.892212,656.157898
	C1792.537720,656.006775 1794.394531,655.712708 1795.844116,656.278198
	C1803.763062,659.366882 1812.122070,657.857239 1820.017212,658.899902
	C1831.593018,660.428650 1842.714355,658.731323 1853.634033,657.308960
	C1864.567505,655.884705 1874.739868,657.908569 1885.230713,658.830078
	C1886.214600,658.916565 1887.177490,659.476929 1888.146118,659.466064
	C1902.961548,659.299011 1917.777344,659.118652 1932.590820,658.829529
	C1936.231812,658.758545 1939.868652,658.004211 1943.501343,658.049377
	C1961.372070,658.271667 1979.247192,659.106934 1997.108154,658.851013
	C2013.013672,658.623169 2028.902954,657.252258 2044.799194,656.382568
	C2046.457520,656.291870 2048.370850,655.585022 2049.731445,656.171692
	C2060.126953,660.654053 2071.161621,658.614075 2081.861816,659.229370
	C2091.046387,659.757446 2100.592285,659.494690 2109.524414,657.523926
	C2120.427246,655.118225 2130.621338,658.016174 2141.129395,658.723816
	C2146.355225,659.075745 2151.626465,658.878845 2156.873047,658.757385
	C2160.183838,658.680664 2163.721436,657.391174 2166.753174,658.186584
	C2179.743408,661.594666 2192.510254,657.602661 2206.360352,657.578613
	C2222.731201,674.017761 2238.724121,690.032654 2253.381104,707.188965
	C2299.103027,760.707458 2339.470215,818.053345 2374.076172,879.396606
	C2377.670898,885.768616 2381.220459,892.177002 2384.481934,898.722412
	C2387.371582,904.521057 2386.914795,907.645874 2382.579102,910.799561
	C2360.714111,926.704163 2337.773926,940.670166 2312.233398,950.060486
	C2271.292725,965.112976 2230.723877,962.427124 2190.264160,948.151123
	C2151.718018,934.550354 2116.533691,914.357971 2082.097900,892.793152
	C2023.661621,856.198364 1968.081909,815.438782 1911.888672,775.568787
	C1861.407349,739.751526 1810.624512,704.359253 1759.938965,668.830505
	C1756.130981,666.161255 1752.036133,663.901123 1747.705078,660.853271
z"
            />
            <path
                fill="#FF2256"
                opacity="1.000000"
                stroke="none"
                d="
M1997.855957,1964.559326
	C2008.802124,1967.706543 2019.505249,1966.510498 2030.377930,1966.338623
	C2046.850830,1966.078247 2063.346924,1968.314575 2079.850586,1968.669434
	C2093.452393,1968.961792 2107.078369,1967.993530 2120.696533,1967.726196
	C2126.281494,1967.616577 2131.878418,1968.063721 2137.462646,1967.940674
	C2155.061768,1967.552979 2172.657227,1966.979614 2190.256104,1966.578857
	C2192.541504,1966.526978 2194.948730,1966.715942 2197.113037,1967.391235
	C2205.262695,1969.933350 2213.260498,1969.974731 2221.665039,1968.139282
	C2226.624268,1967.056274 2232.070068,1968.019043 2237.279785,1968.341553
	C2239.906250,1968.504150 2242.959229,1970.437012 2245.021729,1969.601074
	C2255.887451,1965.197876 2267.374512,1968.985962 2278.318604,1966.514771
	C2280.243164,1966.080200 2282.295654,1965.965576 2284.273438,1966.054688
	C2300.779053,1966.799561 2317.280762,1967.628540 2333.783691,1968.426758
	C2336.090088,1968.538330 2338.398438,1968.607910 2340.704590,1968.720703
	C2348.615967,1969.107544 2356.526855,1969.505005 2365.276611,1969.969849
	C2358.389404,1983.169434 2351.074219,1996.560791 2342.864746,2009.379272
	C2309.838623,2060.947266 2272.693848,2109.432129 2231.546387,2154.758301
	C2206.999756,2181.797363 2181.169434,2207.633545 2153.273926,2232.139160
	C2143.708252,2231.519043 2134.798096,2230.278076 2125.867676,2230.106934
	C2110.332275,2229.808594 2094.710449,2227.793213 2079.271729,2231.500977
	C2078.049316,2231.794678 2076.419189,2231.751953 2075.365234,2231.167969
	C2067.961182,2227.064941 2060.865234,2229.727783 2053.220947,2230.756836
	C2041.240723,2232.369629 2028.102417,2234.179932 2016.951904,2230.866943
	C2003.866577,2226.978760 1991.828491,2229.797607 1979.543457,2230.634277
	C1967.749146,2231.436768 1956.426392,2230.385986 1945.135620,2227.891357
	C1940.639893,2226.898193 1937.135742,2226.613281 1932.997681,2229.408203
	C1930.296265,2231.232910 1926.172241,2231.479248 1922.679565,2231.501221
	C1913.760498,2231.557861 1904.838745,2231.067871 1895.916992,2230.842529
	C1882.290649,2230.498535 1868.603760,2230.860352 1855.056763,2229.647949
	C1845.310059,2228.775635 1835.914673,2230.337646 1826.359863,2230.849365
	C1823.142944,2231.021729 1819.562866,2231.278076 1816.720947,2230.107422
	C1811.400024,2227.915771 1806.576050,2228.460449 1801.192993,2229.740723
	C1796.187378,2230.931641 1790.815796,2230.609619 1785.601929,2230.891846
	C1781.013062,2231.140381 1776.418213,2231.276367 1771.828491,2231.510254
	C1764.613770,2231.877686 1756.934204,2233.806152 1752.192383,2224.839600
	C1778.977051,2209.808105 1804.600952,2193.187012 1829.067871,2175.078613
	C1847.413696,2161.500488 1864.142212,2145.664307 1881.076660,2130.268066
	C1911.257202,2102.828857 1936.669800,2071.293457 1959.040039,2037.261230
	C1974.063477,2014.405640 1986.368652,1990.097534 1997.855957,1964.559326
z"
            />
            <path
                fill="#FF0C91"
                opacity="1.000000"
                stroke="none"
                d="
M1804.270264,1013.146362
	C1799.222168,1020.002930 1792.079712,1020.334351 1784.519165,1019.961182
	C1780.701904,1019.772766 1776.840576,1020.762573 1772.985840,1020.965881
	C1769.399780,1021.155029 1765.504028,1021.891907 1762.261841,1020.821228
	C1755.762329,1018.674927 1749.436157,1018.316345 1742.671875,1018.884216
	C1736.490601,1019.403137 1730.160522,1017.979370 1723.947266,1018.327332
	C1714.811646,1018.839111 1705.737061,1020.375427 1696.605103,1021.050049
	C1692.400269,1021.360657 1688.026001,1021.134583 1683.898804,1020.305054
	C1678.541016,1019.228027 1673.559326,1018.188965 1668.738159,1022.014648
	C1665.091797,1024.908325 1661.191895,1023.536926 1657.437500,1021.752625
	C1651.545532,1018.952515 1645.801025,1018.112061 1639.152588,1020.251282
	C1635.077393,1021.562744 1629.936401,1020.677429 1625.546021,1019.634216
	C1618.848999,1018.042969 1612.445923,1017.859924 1605.898560,1019.994263
	C1599.442017,1022.098999 1592.995850,1022.259583 1586.220947,1020.786011
	C1582.575195,1019.993042 1578.193481,1020.642090 1574.614380,1021.939026
	C1569.228149,1023.890747 1564.469727,1024.574585 1559.505615,1021.056824
	C1554.546387,1017.542542 1548.950928,1017.845886 1543.166016,1018.610107
	C1537.608765,1019.344238 1531.950195,1019.346252 1526.331909,1019.560913
	C1524.692993,1019.623657 1522.632324,1018.608337 1521.459229,1019.287109
	C1513.905884,1023.658020 1506.702637,1021.339417 1499.288086,1018.965210
	C1496.874023,1018.192200 1494.057861,1018.635193 1491.424561,1018.594238
	C1483.857666,1018.476501 1476.271851,1018.023376 1468.727783,1018.395874
	C1459.582886,1018.847412 1450.483398,1020.154480 1441.343628,1020.797791
	C1437.135132,1021.094055 1432.781738,1021.221802 1428.665405,1020.477966
	C1423.023315,1019.458374 1417.890869,1018.257507 1412.439697,1021.938599
	C1410.216431,1023.440002 1405.858887,1023.347656 1403.156616,1022.267883
	C1396.143921,1019.465820 1389.688965,1017.812561 1381.972168,1020.567993
	C1378.042358,1021.971252 1372.773926,1020.545715 1368.354126,1019.432251
	C1362.305298,1017.908386 1356.559814,1017.996460 1350.670410,1019.829895
	C1343.891113,1021.940369 1337.139526,1022.387268 1330.009277,1020.756958
	C1326.398071,1019.931213 1321.994629,1020.697083 1318.420532,1022.018005
	C1312.996948,1024.022461 1308.244507,1024.438599 1303.323486,1020.950928
	C1298.680176,1017.660217 1293.364624,1017.660645 1287.944702,1018.511047
	C1284.015625,1019.127563 1280.038086,1019.963928 1276.100708,1019.875122
	C1270.173584,1019.741516 1264.378662,1018.684570 1258.577148,1021.482422
	C1256.504395,1022.482056 1253.134277,1021.976746 1250.806274,1021.039917
	C1243.759644,1018.204285 1236.618774,1018.402161 1229.263184,1018.835999
	C1223.711182,1019.163513 1218.074585,1018.047424 1212.521118,1018.365662
	C1203.380127,1018.889465 1194.289429,1020.261047 1185.150391,1020.860718
	C1180.619995,1021.157898 1175.958496,1020.955811 1171.484375,1020.210876
	C1166.487915,1019.378784 1161.749756,1018.044800 1157.238525,1021.721252
	C1154.061768,1024.310303 1150.297852,1024.364624 1146.918579,1022.324585
	C1139.328735,1017.742432 1131.790161,1018.459167 1123.408447,1021.926819
	C1126.739990,997.393860 1136.926025,975.106018 1149.151489,953.740662
	C1162.229736,930.884827 1178.668091,910.478149 1198.240356,892.894287
	C1211.620972,880.872986 1225.980713,870.116028 1241.638672,860.849182
	C1281.462891,837.280212 1324.857056,827.180603 1370.368286,823.597961
	C1443.206909,817.864075 1512.875732,830.791504 1578.889526,861.194702
	C1603.571533,872.562317 1627.295288,886.625000 1649.037476,903.518799
	C1660.681519,912.566284 1673.015259,920.728027 1685.062988,929.253662
	C1701.333862,940.767883 1717.585083,952.310608 1733.924683,963.726685
	C1754.787964,978.303223 1775.734497,992.760315 1796.634155,1007.284790
	C1799.092407,1008.992981 1801.481567,1010.800598 1804.270264,1013.146362
z"
            />
            <path
                fill="#FE1B67"
                opacity="1.000000"
                stroke="none"
                d="
M832.202515,1897.184326
	C808.266541,1900.897705 783.958374,1900.885132 759.893799,1899.443726
	C746.841553,1898.661865 734.119873,1900.064331 721.248291,1900.027222
	C714.459290,1900.007690 707.591248,1897.235718 700.838989,1901.379761
	C699.061462,1902.470581 695.690979,1901.205078 693.113708,1900.704468
	C687.564392,1899.626221 682.056274,1898.336548 676.528442,1897.146362
	C675.230225,1896.866821 673.549927,1895.988159 672.673462,1896.487549
	C663.203430,1901.882935 652.979126,1898.279175 643.160767,1899.227051
	C634.544556,1900.058716 626.195251,1900.051147 617.491821,1898.228027
	C610.300537,1896.721680 602.427063,1898.550049 594.852051,1898.768921
	C590.266907,1898.901489 585.673340,1898.841064 581.086121,1898.745728
	C579.436829,1898.711670 577.786194,1898.074951 576.151123,1898.133179
	C562.958374,1898.602295 549.770691,1899.555786 536.578552,1899.606445
	C522.314819,1899.661255 508.047668,1898.911377 493.782654,1898.489258
	C491.127075,1898.410767 488.390747,1897.734131 485.835480,1898.172119
	C475.275940,1899.982788 464.839233,1900.371216 454.100739,1899.024170
	C448.863892,1898.367188 443.226440,1900.903564 436.944702,1901.994751
	C418.948181,1870.097534 404.524323,1836.916626 392.610474,1802.791992
	C380.675659,1768.607422 370.418182,1733.833496 359.635712,1699.252319
	C356.567780,1689.412964 356.871948,1689.259766 364.973114,1683.853394
	C397.077423,1662.428223 431.307159,1645.666016 469.359314,1637.901733
	C512.051208,1629.190552 554.008850,1633.126343 595.268616,1646.517700
	C629.157043,1657.516602 658.179749,1676.578857 683.999329,1700.657959
	C704.225525,1719.520630 722.966614,1739.835815 739.484192,1762.077881
	C748.702087,1774.490356 757.735901,1787.049927 766.511169,1799.778442
	C786.539673,1828.830322 806.369812,1858.019043 826.243652,1887.177124
	C828.302673,1890.197998 830.112366,1893.388794 832.202515,1897.184326
z"
            />
        </svg>
    </>,
    "SongkickIcon"
)
