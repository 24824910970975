import createSvgIcon from '@mui/material/utils/createSvgIcon'

const TiktokIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <g id="Tik Tok">
            <rect width="16" height="16" rx="8" fill="black" />
            <g id="Tik Tok_2">
                <path
                    id="Path"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.0256 6.86674V6.4507C6.8812 6.43026 6.7356 6.41963 6.58975 6.41895C4.80637 6.41895 3.35547 7.8701 3.35547 9.6535C3.35547 10.7475 3.90221 11.7159 4.73626 12.3015C4.17779 11.7042 3.86732 10.917 3.86777 10.0993C3.86777 8.34134 5.27738 6.90779 7.0256 6.86674Z"
                    fill="#00F2EA"
                />
                <path
                    id="Shape"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.10356 11.5766C7.89925 11.5766 8.54836 10.9437 8.57791 10.1548L8.5806 3.11284H9.86709C9.83962 2.96581 9.82573 2.81657 9.82556 2.66699H8.06856L8.06563 9.70921C8.03631 10.4978 7.38696 11.1305 6.59152 11.1305C6.35269 11.1306 6.11747 11.0725 5.90625 10.961C6.04263 11.1513 6.22236 11.3064 6.43056 11.4134C6.63876 11.5205 6.86945 11.5764 7.10356 11.5766ZM12.2687 5.50296V5.11158C11.7959 5.11202 11.3333 4.97449 10.9375 4.71582C11.2845 5.11534 11.7517 5.39151 12.2689 5.50296"
                    fill="#00F2EA"
                />
                <path
                    id="Shape_2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9391 4.7159C10.5513 4.27211 10.3377 3.70266 10.3379 3.11328H9.86717C9.92812 3.43905 10.0545 3.74905 10.2388 4.02452C10.4231 4.29999 10.6613 4.5352 10.9391 4.7159ZM6.59032 8.17666C6.19872 8.17712 5.8233 8.33288 5.54639 8.60979C5.2695 8.88668 5.11375 9.2621 5.11328 9.6537C5.11355 9.92308 5.18741 10.1873 5.32693 10.4177C5.46644 10.6481 5.66626 10.8361 5.90481 10.9612C5.72317 10.7107 5.62537 10.4092 5.62535 10.0998C5.62573 9.70817 5.78146 9.33268 6.05837 9.05572C6.33528 8.77877 6.71075 8.62297 7.10239 8.62252C7.25459 8.62252 7.40043 8.64768 7.53821 8.69092V6.89701C7.39383 6.87657 7.24821 6.86595 7.10239 6.86526C7.07672 6.86526 7.05157 6.86672 7.02617 6.86721V8.24508C6.88521 8.20021 6.73823 8.17715 6.59032 8.17666Z"
                    fill="#FF004F"
                />
                <path
                    id="Path_2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2667 5.50317V6.86882C11.3554 6.86882 10.5114 6.57737 9.82193 6.08266V9.65361C9.82193 11.437 8.37129 12.8879 6.58789 12.8879C5.89871 12.8879 5.25963 12.6705 4.73438 12.3016C5.03636 12.6272 5.40234 12.887 5.8094 13.0646C6.21647 13.2422 6.65582 13.3339 7.09993 13.3337C8.88333 13.3337 10.3342 11.8829 10.3342 10.0997V6.52875C11.0464 7.04088 11.9017 7.31593 12.779 7.31491V5.55742C12.6031 5.55742 12.432 5.53835 12.2667 5.50293"
                    fill="#FF004F"
                />
                <path
                    id="Path_3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.82324 9.65369V6.08276C10.5354 6.59494 11.3907 6.86998 12.268 6.86892V5.50327C11.7507 5.39172 11.2836 5.11545 10.9368 4.7159C10.6589 4.5352 10.4207 4.29999 10.2364 4.02452C10.0522 3.74905 9.92572 3.43905 9.86477 3.11328H8.57828L8.57559 10.1552C8.54604 10.9438 7.89693 11.5768 7.10124 11.5768C6.86713 11.5766 6.63644 11.5206 6.42824 11.4136C6.22006 11.3065 6.04033 11.1515 5.90393 10.9612C5.66533 10.8361 5.46544 10.6482 5.32588 10.4178C5.18633 10.1873 5.11242 9.92312 5.11215 9.65369C5.1126 9.26209 5.26835 8.88667 5.54526 8.60978C5.82215 8.33287 6.19759 8.17712 6.58919 8.17665C6.74115 8.17665 6.88699 8.20158 7.02502 8.24507V6.86721C5.2768 6.90825 3.86719 8.34181 3.86719 10.0998C3.86719 10.95 4.19748 11.7239 4.73568 12.3019C5.27824 12.6839 5.92568 12.8886 6.58919 12.888C8.37259 12.888 9.82324 11.4371 9.82324 9.65369Z"
                    fill="white"
                />
            </g>
        </g>
    </svg>,
    'Search',
)

export default TiktokIcon
