import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import { Box, Card, CardContent, Container, Link, Typography } from '@mui/material'
import { type FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'

const MessageSent: FC = () => {
    return (
        <>
            <Helmet>
                <title>Message Sent | Ticketmetric</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    justifyContent: 'center',
                }}
            >
                <Container
                    sx={{
                        width: '400px',
                        '@media (max-width: 400px)': {
                            width: '100%',
                        },
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} />
                    <Card>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <Box display={'flex'} justifyContent={'center'}>
                                <Box
                                    sx={{
                                        backgroundColor: '#F7F8F9',
                                        borderRadius: '50%',
                                        height: '60px',
                                        width: '60px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <LockResetOutlinedIcon
                                        sx={{ m: 'auto', height: '56px', width: '56px', color: '#0A1B3C' }}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box>
                                    <Typography color="#232859" textAlign={'center'} gutterBottom variant="h5">
                                        Password Reset Complete
                                    </Typography>
                                    <Typography color="#000" textAlign={'center'} variant="body2">
                                        An email containing the Reset Password Link has been sent to your registered
                                        email address.
                                    </Typography>
                                </Box>
                            </Box>
                            <Link
                                component={RouterLink}
                                to="/authentication/login"
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '1rem',
                                }}
                            >
                                <KeyboardBackspaceOutlinedIcon sx={{ color: '#5957d5' }} />
                                <Typography sx={{ fontSize: '14px', textDecoration: 'none', color: '#5957d5' }}>
                                    Return to the login page
                                </Typography>
                            </Link>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>
    )
}
export default MessageSent
