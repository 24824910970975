import createSvgIcon from '@mui/material/utils/createSvgIcon'

const InstagramIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g id="Instagram" clipPath="url(#clip0_789_13717)">
            <g id="Group 597">
                <circle id="Ellipse 2" cx="8.14453" cy="8" r="8" fill="url(#paint0_linear_789_13717)" />
                <g id="Group">
                    <path
                        id="Union"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10.3015 2.66699H5.99016C4.23799 2.66699 2.8125 4.09248 2.8125 5.84465V10.156C2.8125 11.9082 4.23799 13.3337 5.99016 13.3337H10.3015C12.0537 13.3337 13.4792 11.9082 13.4792 10.156V5.84465C13.4791 4.09248 12.0537 2.66699 10.3015 2.66699ZM12.4061 10.156C12.4061 11.3183 11.4638 12.2606 10.3015 12.2606H5.99016C4.82783 12.2606 3.88557 11.3183 3.88557 10.156V5.84465C3.88557 4.68231 4.82783 3.74006 5.99016 3.74006H10.3015C11.4638 3.74006 12.4061 4.68231 12.4061 5.84465V10.156ZM8.1455 5.24121C6.6243 5.24121 5.38672 6.4788 5.38672 7.99997C5.38672 9.52114 6.6243 10.7588 8.1455 10.7588C9.66669 10.7588 10.9043 9.52116 10.9043 7.99997C10.9043 6.47878 9.66669 5.24121 8.1455 5.24121ZM8.1455 9.6857C7.2145 9.6857 6.45979 8.93099 6.45979 7.99999C6.45979 7.06899 7.21452 6.31428 8.1455 6.31428C9.0765 6.31428 9.83121 7.06899 9.83121 7.99999C9.83121 8.93097 9.07648 9.6857 8.1455 9.6857ZM11.5721 5.26262C11.5721 5.62772 11.2762 5.92368 10.9111 5.92368C10.546 5.92368 10.25 5.62772 10.25 5.26262C10.25 4.89753 10.546 4.60156 10.9111 4.60156C11.2762 4.60156 11.5721 4.89753 11.5721 5.26262Z"
                        fill="white"
                    />
                </g>
            </g>
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_789_13717"
                x1="3.39453"
                y1="16"
                x2="10.6445"
                y2="2.07453e-07"
                gradientUnits="userSpaceOnUse">
                <stop stopColor="#FBD06F" />
                <stop offset="0.3" stopColor="#F16C2E" />
                <stop offset="0.6" stopColor="#C82E96" />
                <stop offset="1" stopColor="#4E6AD6" />
            </linearGradient>
            <clipPath id="clip0_789_13717">
                <rect width="16" height="16" fill="white" transform="translate(0.144531)" />
            </clipPath>
        </defs>
    </svg>,
    'Search',
)

export default InstagramIcon
