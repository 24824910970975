import createSvgIcon from '@mui/material/utils/createSvgIcon'

const FacebookIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
        <g id="Facebook">
            <path
                id="Path"
                d="M20.4934 10.1888C20.4934 4.66064 16.0129 0.180176 10.4847 0.180176C4.95653 0.180176 0.476074 4.66064 0.476074 10.1888C0.476074 15.1854 4.13549 19.3257 8.92088 20.0763V13.082H6.3796V10.1888H8.92088V7.9838C8.92088 5.47576 10.4144 4.08981 12.7015 4.08981C13.7962 4.08981 14.9417 4.28529 14.9417 4.28529V6.74836H13.6789C12.4356 6.74836 12.0486 7.52053 12.0486 8.31221V10.1888H14.8244L14.3807 13.082H12.0486V20.0763C16.834 19.3257 20.4934 15.1854 20.4934 10.1888Z"
                fill="#1877F2"
            />
            <path
                id="Path_2"
                d="M14.3805 13.082L14.8242 10.1889H12.0484V8.31225C12.0484 7.52056 12.4354 6.74839 13.6787 6.74839H14.9415V4.28533C14.9415 4.28533 13.796 4.08984 12.7013 4.08984C10.4142 4.08984 8.92068 5.47579 8.92068 7.98384V10.1889H6.37939V13.082H8.92068V20.0763C9.43087 20.1565 9.95282 20.1975 10.4845 20.1975C11.0162 20.1975 11.5382 20.1565 12.0484 20.0763V13.082H14.3805Z"
                fill="white"
            />
        </g>
    </svg>,
    'Search',
)

export default FacebookIcon
