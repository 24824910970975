import { TextField, styled } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme }) => {
    return {
        '& .MuiInputBase-root': {
            padding: 0,
            borderColor: 'transparent',
            color: theme.palette.text.secondary, // Customize input text color
            backgroundColor: theme.palette.background.paper, // Customize input background color
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.text.secondary, // Customize label color
        },
        // Hide the arrows for number input type
        '& input[type=number]': {
            '-moz-appearance': 'textfield', // Firefox
            '&::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none', // Chrome, Safari, Edge, Opera
                margin: 0,
            },
            '&::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none', // Chrome, Safari, Edge, Opera
                margin: 0,
            },
        },
        // Remove padding from the input element
        '& .MuiOutlinedInput-input': {
            padding: 0,
        },
    }
})

// Extended styled TextField with additional border
export const StyledTextFieldWithBorder = styled(StyledTextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.secondary.main, // Customize border color
        },
    },
}))
