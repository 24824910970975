export const GET_NOTES_QUERY_KEY = 'get-notes'
export const GET_SECTIONS_QUERY_KEY = 'get-sections'
export const ANALYTICS_DATA_QUERY_KEY = 'analytics-data'
export const ANALYTICS_COUNT_QUERY_KEY = 'analytics-count'
export const GET_TICKET_TYPES = 'get-ticket-types'

export const GET_PRIMARY_TOP_MOVERS = 'get-primary-top-movers'

export const PRESALES_QUERY_KEY = 'presales'
export const GET_PRESALES_CODES = 'get-presales-codes'

export const GET_EVENT_QUERY_KEY = 'get-event'
export const GET_CATEGORIES_QUERY_KEY = 'get-categories'
export const GET_SUMMARY_OVER_TIME_LISTINGS_QUERY_KEY = 'get-summary-over-time-listings'
export const GET_SECTION_INFO = 'get-section-info'
export const GET_BUYING_LIST = 'get-buying-list'
export const GET_WATCHLIST = 'get-watchlist'

export const GET_SECONDARY_ACTIVE_LISTINGS_QUERY_KEY = 'get-secondary-active-listings'
export const GET_PRIMARY_ACTIVE_LISTINGS_QUERY_KEY = 'get-primary-active-listings'
export const GET_PRIMARY_ACTIVE_LISTINGS_OVER_TIME_QUERY_KEY = 'get-primary-active-listings-over-time'

export const GET_SECONDARY_INVENTORY_QUERY_KEY = 'get-secondary-inventory'
export const GET_SECONDARY_SUMMARY_SALES_QUERY_KEY = 'get-secondary-summary-sales'
export const GET_PRIMARY_INVENTORY_QUERY_KEY = 'get-primary-inventory'
export const GET_FIRST_SECTION_INFORMATION_QUERY_KEY = 'get-first-section-information'
export const GET_SCRAP_SECTION_INFORMATION_QUERY_KEY = 'get-scrap-section-information'
export const GET_SCRAP_SECTION_INFORMATION_STUBHUB_QUERY_KEY = 'get-scrap-section-information'

export const GET_HISTORICAL_PRICES_QUERY_KEY = 'get-historical-prices'

export const GET_SECTION_DETAILS = 'get-section-details'
export const GET_SOLD_INFORMATION_QUERY_KEY = 'get-sold-information'
export const GET_COMPARISON_OF_PRIMARY_SECONDARY_PRICES = 'get-comparison-of-primary-secondary-prices'

export const GET_USER_FILTERS = 'get-user-filters'

export const GET_VENUES_MATCH = 'get-venues-match'

export const GET_ARTIST_DATA = 'get-artist-data'
export const GET_ARTIST_EVENTS = 'get-artist-events'

export const GET_PRIMARY_FAVORITES = 'get-primary-favorites'
export const GET_SECONDARY_FAVORITES = 'get-secondary-favorites'
