import { StripeProvider } from './stripeProvider'
import { QueryProvider } from './queryProvider'
import { QueryDevtoolProvider } from './queryDevtoolProvider'
import { ThemeProvider } from './themeProvider'
import { HelmetProvider } from 'react-helmet-async'
import { StoreProvider } from './reduxProvider'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BrowserRouter } from 'react-router-dom'
// FIXME:
import { SettingsProvider } from '../../contexts/SettingsContext'
import { AuthProvider } from '../../contexts/JWTContext'
import { UserLimitsContextProvider } from '../../contexts/UserLimitsContext'
import { SentryProvider } from './sentryProvider'

interface Props {
    children?: React.ReactNode
}

export const MainProvider: React.FC<Props> = ({ children }) => {
    return (
        <SentryProvider>
            <StripeProvider>
                <HelmetProvider>
                    <StoreProvider>
                        <StyledEngineProvider injectFirst>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <SettingsProvider>
                                    <BrowserRouter>
                                        <AuthProvider>
                                            <UserLimitsContextProvider>
                                                <QueryProvider>
                                                    <QueryDevtoolProvider />
                                                    <ThemeProvider>
                                                        {/*  */}
                                                        {children}
                                                        {/*  */}
                                                    </ThemeProvider>
                                                </QueryProvider>
                                            </UserLimitsContextProvider>
                                        </AuthProvider>
                                    </BrowserRouter>
                                </SettingsProvider>
                            </LocalizationProvider>
                        </StyledEngineProvider>
                    </StoreProvider>
                </HelmetProvider>
            </StripeProvider>
        </SentryProvider>
    )
}
