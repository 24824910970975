export const headers = [
    { name: 'Event', key: 'event', filterable: false },
    { name: 'Rank', key: 'popularity', filterable: false },
    { name: 'Date', key: 'date', filterable: false },
    { name: 'Venue', key: 'venue', filterable: false },
    { name: 'Location', key: 'location', filterable: false },
    { name: 'Capacity', key: 'capacity', filterable: false },
    { name: 'Primary Availability', key: 'last_fetched_details.current_date_time', filterable: true },
    { name: 'Secondary Availability', key: 'stubhub_last_fetched_at', filterable: true },
    { name: 'Primary Price', key: '', filterable: true, infoIcon: 'primaryPriceInfoModal' },
    { name: 'Secondary Get In', key: 'getInPrice', filterable: false, infoIcon: 'getInPriceInfoModal' },
    { name: 'Actions', key: 'actions', filterable: false },
]

export const layoutGrid = '3fr 0.4fr 0.6fr 0.9fr 0.6fr 0.8fr 0.7fr 1fr 0.6fr 0.6fr 0.4fr 0.1fr'
