import { ArtistMetricInt, PersonalMetricInterface } from 'src/types/local/artistMetricResponce'
import { artistsDatumInt } from 'src/types/local/upSalesResp'
import axiosClient from 'src/utils/axios'
import { FrontEndError } from 'src/utils/error'

interface GetEvent {
    per_page?: number | string
    page?: number | string
    sort_by?: string | number
    sort_order?: string
    filterByName?: string
}

interface GetAllArtist {
    name?: string
    perPage?: number
    currentPage?: number
}

interface EventDetails {
    eventId: string
    perPage?: number
    currentPage?: number
    type?: string
}

interface PriceDrop {
    event_id: string
    sections: any[]
    minimum_quantity: number
    maximum_price: number
    market_place: string
}

interface Notes {
    event_id: string
    text: string
    type: string
}

class EventsAPI {
    async getAllEvents({ per_page, page, sort_by, sort_order, filterByName }: GetEvent) {
        let allEvents = await axiosClient().get(
            `api/events/all?per_page=${per_page}&page=${page || 1}$sort_by=${sort_by || 'name'}&sort_order=${
                sort_order || 'asc'
            }&name=${filterByName || ''}`
        )
        return new Promise((resolve, reject) => {
            try {
                if (allEvents.data.events.length < 1) {
                    resolve({
                        events: [],
                        pagination: allEvents.data.pagination,
                    })
                    return
                }

                if (allEvents) {
                    resolve({
                        events: allEvents.data.events,
                        pagination: allEvents.data.pagination,
                    })
                }
            } catch (err) {
                FrontEndError(err)
                reject(new Error(err.response.data.message || 'Internal server error'))
            }
        })
    }

    async getEventDetails({ eventId }: EventDetails) {
        const url = `api/events/${eventId}`

        try {
            const eventsDetails = await axiosClient().get(url)
            return eventsDetails.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getDataArray({ eventId }: EventDetails) {
        const url = `api/events/get-data-array/${eventId}`

        try {
            const eventsDetails = await axiosClient().get(url)
            return eventsDetails.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getHistoricalPrices({ eventId, perPage, currentPage, type }: EventDetails) {
        const url = `api/events/historical/price/${eventId}?page=${currentPage}&perPage=${perPage}&type=${type}`

        try {
            const historicalPrices = await axiosClient().get(url)
            return historicalPrices.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async scrapeEventData({ eventId }: EventDetails) {
        const url = `/api/events/scrap-section-information/${eventId}`
        try {
            const scrapedData = await axiosClient().get(url)
            return scrapedData?.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response?.data?.Message || 'Internal server error',
            }
            return error
        }
    }

    async addPriceDropAlert({ event_id, sections, minimum_quantity, maximum_price, market_place }: PriceDrop) {
        const url = '/api/events/add-price-drop-alert'

        try {
            const eventsDetails = await axiosClient().post(url, {
                event_id,
                sections,
                minimum_quantity,
                maximum_price,
                market_place,
            })
            return eventsDetails.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async saveNotes({ event_id, text, type }: Notes) {
        const url = '/api/events/notes/save'
        try {
            const notes = await axiosClient().post(url, { event_id, text, type })
            return notes.data
        } catch (err) {
            FrontEndError(err)
            const error = {
                isError: true,
                errorMessage: err.response.data.Message || 'Internal server error',
            }
            return error
        }
    }

    async getAllArtist({ name, perPage, currentPage }: GetAllArtist) {
        let allArtists = await axiosClient().get(
            `api/artists/metric-all?per_page=${perPage || 5}&page=${currentPage || 1}${name && `&name=${name}`}`
        )
        return new Promise<ArtistMetricInt>((resolve, reject) => {
            try {
                resolve(allArtists.data)
            } catch (err) {
                FrontEndError(err)
                reject(new Error(err.response.data.message || 'Internal server error'))
            }
        })
    }

    async getArtistById({ id }) {
        let artist = await axiosClient().get('/api/artists/artist-bio/' + id)

        return new Promise<artistsDatumInt>((resolve, reject) => {
            try {
                resolve(artist.data)
            } catch (err) {
                FrontEndError(err)
                reject(new Error(err.response.data.message || 'Internal server error'))
            }
        })
    }

    async getArtistLastEvents({ id, page, perPage }) {
        let artist = await axiosClient().get(`/api/events/artist-shows?artistId=${id}&page=${page}&per_page=${perPage}`)

        return new Promise<PersonalMetricInterface>((resolve, reject) => {
            try {
                resolve(artist.data)
            } catch (err) {
                FrontEndError(err)
                reject(new Error(err.response.data.message || 'Internal server error'))
            }
        })
    }

    async addUserMissingEvent({ url, type }: { url: string; type: 'all' | 'my_primary' | 'my_secondary' }) {
        return axiosClient().post(`/api/events/add-user-missing`, { url, type })
    }
}

export const API = new EventsAPI()
