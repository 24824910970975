import { useEffect, useState } from 'react'

const useCollapsed = (initialState: boolean = true) => {
    const [collapsed, setCollapsed] = useState(initialState)

    const toggleCollapsed = (condition: boolean) => {
        setCollapsed(condition)
    }
    useEffect(() => {
        toggleCollapsed(collapsed)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { collapsed, toggleCollapsed }
}

export default useCollapsed
