import createSvgIcon from '@mui/material/utils/createSvgIcon'

const SearchIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
        <g id="Search 2">
            <path
                id="Vector"
                d="M18.5294 17.4698C18.2365 17.1769 17.7617 17.1769 17.4688 17.4698C17.1759 17.7626 17.1759 18.2375 17.4688 18.5304L18.5294 17.4698ZM21.4686 22.5304C21.7615 22.8233 22.2364 22.8233 22.5293 22.5304C22.8222 22.2375 22.8222 21.7626 22.5293 21.4697L21.4686 22.5304ZM17.4688 18.5304L21.4686 22.5304L22.5293 21.4697L18.5294 17.4698L17.4688 18.5304ZM9.99902 18.25C5.44267 18.25 1.74902 14.5563 1.74902 10H0.249023C0.249023 15.3848 4.61425 19.75 9.99902 19.75V18.25ZM18.249 10C18.249 14.5563 14.5554 18.25 9.99902 18.25V19.75C15.3838 19.75 19.749 15.3848 19.749 10H18.249ZM9.99902 1.75C14.5554 1.75 18.249 5.44365 18.249 10H19.749C19.749 4.61522 15.3838 0.25 9.99902 0.25V1.75ZM9.99902 0.25C4.61425 0.25 0.249023 4.61522 0.249023 10H1.74902C1.74902 5.44365 5.44267 1.75 9.99902 1.75V0.25Z"
                fill="black"
            />
        </g>
    </svg>,
    'Search',
)

export default SearchIcon
