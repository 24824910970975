import createSvgIcon from '@mui/material/utils/createSvgIcon'

const DeezerIcon = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        imageRendering="optimizeQuality"
        fillRule="evenodd"
        clipRule="evenodd"
        viewBox="0 0 512 512">
        <defs>
            <linearGradient id="prefix__a" gradientUnits="userSpaceOnUse" x1="229.838" y1="306.157" x2="221.992"
                            y2="350.606">
                <stop offset="0" stopColor="#2C8C9D" />
                <stop offset=".039" stopColor="#298E9A" />
                <stop offset=".388" stopColor="#129C83" />
                <stop offset=".722" stopColor="#05A475" />
                <stop offset="1" stopColor="#00A770" />
            </linearGradient>
            <linearGradient id="prefix__b" gradientUnits="userSpaceOnUse" x1="323.864" y1="243.54" x2="385.972"
                            y2="275.188">
                <stop offset="0" stopColor="#2839BA" />
                <stop offset="1" stopColor="#148CB3" />
            </linearGradient>
            <linearGradient id="prefix__c" gradientUnits="userSpaceOnUse" x1="103.234" y1="173.896" x2="163.535"
                            y2="173.896">
                <stop offset="0" stopColor="#F6A500" />
                <stop offset="1" stopColor="#F29100" />
            </linearGradient>
            <linearGradient id="prefix__d" gradientUnits="userSpaceOnUse" x1="176.091" y1="173.896" x2="236.393"
                            y2="173.896">
                <stop offset="0" stopColor="#F29100" />
                <stop offset="1" stopColor="#D12F5F" />
            </linearGradient>
            <linearGradient id="prefix__e" gradientUnits="userSpaceOnUse" x1="248.972" y1="173.896" x2="309.274"
                            y2="173.896">
                <stop offset="0" stopColor="#B4197C" />
                <stop offset="1" stopColor="#472EAD" />
            </linearGradient>
            <linearGradient id="prefix__f" gradientUnits="userSpaceOnUse" x1="321.829" y1="173.895" x2="382.132"
                            y2="173.895">
                <stop offset="0" stopColor="#2839BA" />
                <stop offset="1" stopColor="#3072B7" />
            </linearGradient>
            <linearGradient id="prefix__g" gradientUnits="userSpaceOnUse" x1="253.336" y1="215.101" x2="318.367"
                            y2="234.115">
                <stop offset="0" stopColor="#B4197C" />
                <stop offset="1" stopColor="#373AAC" />
            </linearGradient>
            <linearGradient id="prefix__h" gradientUnits="userSpaceOnUse" x1="169.905" y1="260.323" x2="234.464"
                            y2="236.836">
                <stop offset="0" stopColor="#FFCB00" />
                <stop offset="1" stopColor="#D12F5F" />
            </linearGradient>
            <linearGradient id="prefix__i" gradientUnits="userSpaceOnUse" x1="216.6" y1="336.316" x2="266.702"
                            y2="290.075">
                <stop offset="0" stopColor="#FFCF00" />
                <stop offset="1" stopColor="#ED743B" />
            </linearGradient>
        </defs>
        <path d="M256 0c141.385 0 256 114.615 256 256S397.385 512 256 512 0 397.385 0 256 114.615 0 256 0z" />
        <path fill="#29AB70" d="M382.131 156.015h-60.305v35.194h60.305z" />
        <path fill="url(#prefix__a)" d="M321.829 205.016h60.302v35.207h-60.302z" />
        <path fill="url(#prefix__b)" d="M321.797 254.036h60.296v35.202h-60.296z" />
        <path fill="url(#prefix__c)" d="M103.234 303.009h60.301v35.216h-60.301z" />
        <path fill="url(#prefix__d)" d="M176.091 303.009h60.302v35.216h-60.302z" />
        <path fill="url(#prefix__e)" d="M248.972 303.009h60.302v35.216h-60.302z" />
        <path fill="url(#prefix__f)" d="M321.829 303.009h60.304v35.217h-60.304z" />
        <path fill="url(#prefix__g)" d="M248.972 253.986h60.301v35.165h-60.301z" />
        <path fill="url(#prefix__h)" d="M176.083 254.016h60.318v35.174h-60.318z" />
        <path fill="url(#prefix__i)" d="M176.106 205.03h60.271v35.181h-60.271z" />
    </svg>,
    'DeezerIcon',
)

export default DeezerIcon
