export default function NoteIcont({color="#475467"}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_852_657)">
        <path
          d="M9.16602 3.33344H5.66602C4.26588 3.33344 3.56582 3.33344 3.03104 3.60593C2.56063 3.84561 2.17818 4.22806 1.9385 4.69847C1.66602 5.23324 1.66602 5.93331 1.66602 7.33344V14.3334C1.66602 15.7336 1.66602 16.4336 1.9385 16.9684C2.17818 17.4388 2.56063 17.8213 3.03104 18.061C3.56582 18.3334 4.26588 18.3334 5.66602 18.3334H12.666C14.0661 18.3334 14.7662 18.3334 15.301 18.061C15.7714 17.8213 16.1538 17.4388 16.3935 16.9684C16.666 16.4336 16.666 15.7336 16.666 14.3334V10.8334M6.66599 13.3334H8.06145C8.4691 13.3334 8.67292 13.3334 8.86474 13.2874C9.0348 13.2466 9.19737 13.1792 9.34649 13.0878C9.51468 12.9848 9.65881 12.8406 9.94706 12.5524L17.916 4.58344C18.6064 3.89309 18.6064 2.7738 17.916 2.08344C17.2257 1.39309 16.1064 1.39308 15.416 2.08344L7.44704 10.0524C7.15879 10.3406 7.01466 10.4848 6.91159 10.653C6.82021 10.8021 6.75287 10.9647 6.71204 11.1347C6.66599 11.3265 6.66599 11.5304 6.66599 11.938V13.3334Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_852_657">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
