import createSvgIcon from '@mui/material/utils/createSvgIcon'

const TwitterIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <g id="X" clipPath="url(#clip0_789_13782)">
            <g id="Group">
                <path
                    id="Vector"
                    d="M8.14453 0C3.72641 0 0.144531 3.58187 0.144531 8C0.144531 12.4181 3.72641 16 8.14453 16C12.5627 16 16.1445 12.4181 16.1445 8C16.1445 3.58187 12.5627 0 8.14453 0Z"
                    fill="black"
                />
                <path
                    id="Vector_2"
                    d="M9.01789 7.27586L12.4426 3.29492H11.6311L8.65737 6.75152L6.28232 3.29492H3.54297L7.13452 8.5219L3.54297 12.6965H4.35456L7.49484 9.04625L10.0031 12.6965H12.7424L9.01768 7.27586H9.01789ZM4.64698 3.90588H5.89353L11.6314 12.1134H10.3849L4.64698 3.90588Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_789_13782">
                <rect width="16" height="16" fill="white" transform="translate(0.144531)" />
            </clipPath>
        </defs>
    </svg>,
    'Search',
)

export default TwitterIcon
