import React, { createContext, useState, ReactNode, useEffect, useContext } from 'react'
import { authApi } from 'src/services/authApi'
import { userApi } from 'src/services/userApi'
import AuthContext from './JWTContext'

interface IUserLimitsContext {
    fetchCount: number
    maxFetchCount: number
    wasteFetch: () => void

    primaryFavCount: number
    maxPrimaryFavCount: number
    wastePrimaryFav: () => void

    secondaryFavCount: number
    maxSecondaryFavCount: number
    wasteSecondaryFav: () => void

    tmDropCheckerCount: number
    maxTmDropCheckerCount: number
    wasteTmDropChecker: () => void

    tmLowInventoryCount: number
    maxTmLowInventoryCount: number
    wasteTmLowInventory: () => void

    axsDropCheckerCount: number
    maxAxsDropCheckerCount: number
    wasteAxsDropChecker: () => void

    axsLowInventoryCount: number
    maxAxsLowInventoryCount: number
    wasteAxsLowInventory: () => void

    priceDropCount: number
    maxPriceDropCount: number
    wastePriceDrop: () => void
}

const initialUserLimitsContext: IUserLimitsContext = {
    fetchCount: 0,
    maxFetchCount: 0,
    wasteFetch: () => {},

    primaryFavCount: 0,
    maxPrimaryFavCount: 0,
    wastePrimaryFav: () => {},

    secondaryFavCount: 0,
    maxSecondaryFavCount: 0,
    wasteSecondaryFav: () => {},

    tmDropCheckerCount: 0,
    maxTmDropCheckerCount: 0,
    wasteTmDropChecker: () => {},

    tmLowInventoryCount: 0,
    maxTmLowInventoryCount: 0,
    wasteTmLowInventory: () => {},

    axsDropCheckerCount: 0,
    maxAxsDropCheckerCount: 0,
    wasteAxsDropChecker: () => {},

    axsLowInventoryCount: 0,
    maxAxsLowInventoryCount: 0,
    wasteAxsLowInventory: () => {},

    priceDropCount: 0,
    maxPriceDropCount: 0,
    wastePriceDrop: () => {},
}

interface UserContextProviderProps {
    children: ReactNode
}

export const UserLimitsContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
    const layout = useContext(AuthContext)

    const [userLimitsContext, setUserLimitsContext] = useState<IUserLimitsContext>(initialUserLimitsContext)

    const getLimits = async () => {
        const userData = await authApi.me()
        const statsData = await userApi.getStats()

        setUserLimitsContext((prevContext) => ({
            ...prevContext,
            fetchCount: statsData.pull_frequency,
            maxFetchCount: Number(userData.payment_plans[0].total_allowed_fetch),

            primaryFavCount: statsData.primary_fav_count,
            maxPrimaryFavCount: Number(userData.payment_plans[0].primaryFavorites),

            secondaryFavCount: statsData.secondary_fav_count,
            maxSecondaryFavCount: Number(userData.payment_plans[0].SecondaryFavorites),

            tmDropCheckerCount: statsData.tm_drop_checker_alerts_count,
            maxTmDropCheckerCount: Number(userData.payment_plans[0].TMDropchecker),

            tmLowInventoryCount: statsData.tm_low_inventory_alerts_count,
            maxTmLowInventoryCount: Number(userData.payment_plans[0].TMLowInventoryAlert),

            axsDropCheckerCount: statsData.axs_drop_checker_alerts_count,
            maxAxsDropCheckerCount: Number(userData.payment_plans[0].AXSDropchecker),

            axsLowInventoryCount: statsData.axs_low_inventory_alerts_count,
            maxAxsLowInventoryCount: Number(userData.payment_plans[0].AXSLowInventoryAlert),

            priceDropCount: statsData.price_drop_alerts_count,
            maxPriceDropCount: Number(userData.payment_plans[0].PriceDropAlert),
        }))
    }

    useEffect(() => {
        if (layout.user) {
            getLimits()
        }
    }, [layout])

    const wasteFetch = () => {
        if (userLimitsContext.fetchCount < userLimitsContext.maxFetchCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                fetchCount: prevContext.fetchCount + 1,
            }))
        }
    }

    const wastePrimaryFav = () => {
        if (userLimitsContext.primaryFavCount < userLimitsContext.maxPrimaryFavCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                primaryFavCount: prevContext.primaryFavCount + 1,
            }))
        }
    }

    const wasteSecondaryFav = () => {
        if (userLimitsContext.secondaryFavCount < userLimitsContext.maxSecondaryFavCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                secondaryFavCount: prevContext.secondaryFavCount + 1,
            }))
        }
    }

    const wasteTmDropChecker = () => {
        if (userLimitsContext.tmDropCheckerCount < userLimitsContext.maxTmDropCheckerCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                tmDropCheckerCount: prevContext.tmDropCheckerCount + 1,
            }))
        }
    }

    const wasteTmLowInventory = () => {
        if (userLimitsContext.tmLowInventoryCount < userLimitsContext.maxTmLowInventoryCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                tmLowInventoryCount: prevContext.tmLowInventoryCount + 1,
            }))
        }
    }

    const wasteAxsDropChecker = () => {
        if (userLimitsContext.axsDropCheckerCount < userLimitsContext.maxAxsDropCheckerCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                axsDropCheckerCount: prevContext.axsDropCheckerCount + 1,
            }))
        }
    }

    const wasteAxsLowInventory = () => {
        if (userLimitsContext.axsLowInventoryCount < userLimitsContext.maxAxsLowInventoryCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                axsLowInventoryCount: prevContext.maxAxsLowInventoryCount + 1,
            }))
        }
    }

    const wastePriceDrop = () => {
        if (userLimitsContext.priceDropCount < userLimitsContext.maxPriceDropCount) {
            setUserLimitsContext((prevContext) => ({
                ...prevContext,
                priceDropCount: prevContext.priceDropCount + 1,
            }))
        }
    }

    return (
        <UserLimitsContext.Provider
            value={{
                ...userLimitsContext,
                wasteFetch,
                wastePrimaryFav,
                wasteSecondaryFav,
                wasteTmDropChecker,
                wasteTmLowInventory,
                wasteAxsDropChecker,
                wasteAxsLowInventory,
                wastePriceDrop,
            }}
        >
            {children}
        </UserLimitsContext.Provider>
    )
}

export const UserLimitsContext = createContext<IUserLimitsContext>(initialUserLimitsContext)
