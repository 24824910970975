import { WatchlistResponse } from 'src/types/local/watchlistResponse'
import axiosClient from 'src/utils/axios'
import { FrontEndError } from 'src/utils/error'

// interface IUpdatePresaleCode {
//     eventId: string
//     presaleName: string
//     startDateTime: string
//     endDateTime?: string
//     code: string
// }

class WatchlistServiceAPI {
    async getWatchlist({
        page,
        perPage,
        valueInput,
        filtringState,
        categories,
        searchParams,
        isDisabledFilters,
        spotifyMin,
        spotifyMax,
        capacityMin,
        capacityMax,
        priceMin,
        priceMax,
        filterShow,
    }): Promise<WatchlistResponse> {
        try {
            const params = {
                per_page: perPage,
                category: categories.join(','),
                page,
                saleType: filtringState.eventSelect.join(','),
                search: valueInput,
                countryCode: filtringState.countries.join(','),
                platform: filtringState.platformSelect.join(','),
                spotifyMin,
                spotifyMax,
                capacityMin,
                capacityMax,
                time: filtringState.timeSelect,
                priceMin,
                priceMax,
            }
            if (params.time.length === 0) delete params.time
            if (!filterShow) {
                delete params.spotifyMin
                delete params.spotifyMax
                delete params.capacityMin
                delete params.capacityMax
                delete params.priceMin
                delete params.priceMax
                delete params.category
                delete params.time
                delete params.saleType
                delete params.countryCode
            }
            Object.entries(isDisabledFilters).forEach(([key, value]) => {
                if (key === 'spotifyRankValue' && value) {
                    delete params.spotifyMin
                    delete params.spotifyMax
                }
                if (key === 'capacityValue' && value) {
                    delete params.capacityMin
                    delete params.capacityMax
                }
                if (key === 'priceValue' && value) {
                    delete params.priceMin
                    delete params.priceMax
                }
            })
            return (await axiosClient().get('/api/presales/watchlist', { params })).data
        } catch (err) {
            FrontEndError(err)
        }
    }
}

export const WatchListAPI = new WatchlistServiceAPI()
